import * as Yup from "yup";

export const CreateTruckFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nome é obrigatório')
    .max(17, 'O nome pode ter no máximo 17 caracteres'),
  licensePlate: Yup.string()
    .required('Placa é obrigatória')
    .max(7, 'A placa pode ter no máximo 7 caracteres'),
  generatorModel: Yup.string()
    .required('Modelo do gerador é obrigatório')
    .max(100, 'O modelo pode ter no máximo 100 caracteres'),
});

export const CreateTruckFormId = 'create-truck-form';
