import { gql } from "@apollo/client";

export const GET_PRODUCT_CATEGORIES = gql`
  query ProductCategories {
    productCategories {
      id
      name
    }
  }
`;

export const CREATE_PRODUCT_CATEGORY = gql`
  mutation CreateProductCategory($input: CreateProductCategoryInputType!) {
    createProductCategory(input: $input) {
      id
      name
    }
  }
`;
