import React, { useMemo } from "react";
import { Flex, Text, IconButton } from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward, IoIosMore } from "react-icons/io";
import colors from "../../shared/utils/constants/colors";

type IProps = {
  totalPage: number;
  canPreviousPage?: boolean;
  previousPage?: (p: number) => void;
  nextPage?: (p: number) => void;
  canNextPage?: boolean;
  pageIndex: number;
  setPage: (page: number) => void;
};

function Pagination({
  totalPage,
  pageIndex,
  setPage,
}: IProps) {
  const pages = [] as any;

  const actualPage = pageIndex || 1;
  const totalText = `Página ${actualPage} de ${totalPage}`;
  const maxVisibleButtons = 4;

  let maxLeft = actualPage - Math.floor(maxVisibleButtons / 2);
  let maxRight = actualPage + Math.floor(maxVisibleButtons / 2);

  if (maxLeft < 1) {
    maxLeft = 1;
    maxRight = maxVisibleButtons;
  }

  if (maxRight > totalPage) {
    maxLeft = actualPage - (maxVisibleButtons - 1);
    maxRight = actualPage;

    if (maxLeft < 1) maxLeft = 1;
  }

  for (let p = maxLeft; p <= maxRight; p++) {
    pages.push(p);
  }

  const pageInfo = useMemo(() => ({
    isFirstPage: pageIndex === 1,
    isLastPage: pageIndex === totalPage,
  }), [pageIndex, totalPage]);

  return (
    <Flex
      align="center"
      justify="space-between"
      border="2px solid"
      borderColor={(colors.primary.default, "transparent")}
      p={4}
      bg={colors.argon.white}
      borderRadius="5px"
      width="calc(100% - 20px)"
      marginX="auto"
      mt={"auto"}
      mb={4}
      boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
    >
      <Text
        color={colors.argon.textColorDark}
        fontFamily="Open Sans"
        fontSize={16}
        fontWeight={400}
        mr={4}
        mt={0}
      >
        {totalText}
      </Text>
      <Flex>
        <IconButton
          aria-label="page"
          icon={<IoIosArrowBack />}
          isDisabled={pageInfo.isFirstPage}
          onClick={() => setPage(pageIndex - 1)}
          bg={colors.argon.white}
          color={colors.argon.default}
          borderRadius="50%"
          size="sm"
          fontSize={20}
          ml={2}
          _disabled={{
            bg: colors.gray.light03,
            opacity: 0.2,
          }}
          _hover={{
            bg: colors.argon.white,
          }}
          _focus={{
            boxShadow: "none",
          }}
        />
        {maxLeft > 1 && (
          <Text
            as="span"
            fontFamily="Open Sans"
            color={colors.argon.textColorDark}
            fontSize={20}
            ml={2}
          >
            <IoIosMore />
          </Text>
        )}
        {pages.map((p: any) => {
          if (p === actualPage) {
            return (
              <IconButton
                aria-label="page"
                key={p}
                icon={<Text fontSize={20}>{p}</Text>}
                onClick={() => setPage(p)}
                bg={colors.argon.default}
                color={colors.argon.white}
                size="sm"
                fontSize={20}
                padding={2}
                ml={2}
                _hover={{
                  bg: colors.argon.default,
                }}
                _focus={{
                  boxShadow: "none",
                }}
              />
            );
          } else {
            return (
              <IconButton
                aria-label="page"
                key={p}
                icon={<Text fontSize={20}>{p}</Text>}
                onClick={() => setPage(p)}
                bg={colors.argon.white}
                color={colors.argon.default}
                borderRadius="50%"
                size="sm"
                fontSize={20}
                ml={2}
                _hover={{
                  bg: colors.argon.white,
                }}
                _focus={{
                  boxShadow: "none",
                }}
              />
            );
          }
        })}
        {maxRight < totalPage && (
          <Text
            as="span"
            fontFamily="Open Sans"
            color={colors.argon.textColorDark}
            fontSize={20}
            ml={2}
          >
            <IoIosMore />
          </Text>
        )}
        <IconButton
          aria-label="next-page"
          icon={<IoIosArrowForward />}
          isDisabled={pageInfo.isLastPage}
          onClick={() => setPage(pageIndex + 1)}
          bg={colors.argon.white}
          color={colors.argon.default}
          borderRadius="50%"
          size="sm"
          fontSize={20}
          ml={2}
          _disabled={{
            bg: colors.gray.light03,
            opacity: 0.2,
          }}
          _hover={{
            bg: colors.argon.white,
          }}
          _focus={{
            boxShadow: "none",
          }}
        />
      </Flex>
    </Flex>
  );
}

export default Pagination;
