export type IRegisterProductFormData = {
    brand: string,
    description: string;
    stock_unit: string
    class: string,
    quantity: number,
    manufacturer_sku_code: number,
    minimum_stock: number,
    maximum_stock: number,
    cost_price: number,
    selling_price: number,
    comission_percentage: number;
    category: string;
    packaging: string;
    name: string;
}

export type IRegisterBrandFormData = {
    name: string;
}

export type IRegisterCategoryFormData = {
    name: string;
}

export type IRegisterPackagingFormData = {
    name: string;
}

export type IBrand = {
    name: string;
    id: string
}

export type ICategory = {
    name: string;
    id: string
}

export type IPackaging = {
    name: string;
    id: string
}

export type IProduct = {
    id: number
    manufacturer_sku_code: string
    name: string
    stock_unit: string
    quantity: number
    cost_price: number
    selling_price: number
    comission_percentage: number
    maximum_stock: number
    minimum_stock: number
    class: string
    brand: {
      name: string
      id: string
    }
    category: {
        name: string
        id: string
    }
    packaging: {
        name: string
        id: string
    }
    description: string
    created_at: string
    updated_at: string
}

export type IFilterStockFormData = {
    manufacturer_sku_code?: string,
    [key: string]: any
}

export type APIFiltersStock = {
    manufacturer_sku_code?: string,
}

export enum StockFilterTranslateEnum {
    manufacturer_sku_code = 'SKU',
  }

  export type IUpdateProductVariables = {
    input: {
        productId: number;
        brand: string,
        description: string;
        stock_unit: string
        class: string,
        quantity : number,
        manufacturer_sku_code: number,
        minimum_stock: number,
        maximum_stock: number,
        cost_price: number,
        selling_price: number,
        comission_percentage: number;
        category: string;
        packaging: string;
        name: string;
    },
}