import { format } from "date-fns";
import React, { useState } from "react";

import Loading from "../../../components/Loading";
import Text from "../../../components/Text";

import colors from "../../../shared/utils/constants/colors";
import { launchToast } from "../../../shared/utils/launchToast";
import processError from "../../../shared/utils/processError";

import ExcelJS from "exceljs";
import PowerSupplyEnum from "../../../shared/enums/PowerSupplyEnum";
import calculateDistanceBetweenHours from "../../../shared/utils/calculateDistanceBetweenHours";
import { calculateSizeByWeigthPet } from "../../../shared/utils/calculateSizeByWeigthPet";
import convertToCurrencyFormat from "../../../shared/utils/convertToCurrencyFormat";
import * as Styles from "./styles";

import { useAuth } from "../../../hooks/Auth";
import AppointmentStatusEnum from "../../../shared/enums/AppointmentStatusEnum";
import { IAppointmentFiltersFormData } from "./forms/AppointmentFinancialFiltersForm/types";
import AppointmentFiltersForm from "./forms/AppointmentFinancialFiltersForm";
import Button from "components/Button";
import { AppointmentFiltersFormId } from "./forms/AppointmentFinancialFiltersForm/constants";
import { IFinancialReport, loadFinancialExportableReport } from "./graphQL";


enum TransactionTaypeEnum {
  definir = 0,
  cartão = 1,
  link = 2,
  pix = 3,
  transferencia = 4,
  dinheiro = 5,
  newLink = 6,
  linkavulso = 7,
  reembolso = 8,
};

const AppointmentsReport: React.FC = () => {
  const { user } = useAuth();

  const [exportingExcel, setExportingExcel] = useState(false);
  const handleExportFullData = async (formData: IAppointmentFiltersFormData) => {
    if (!formData.start_date || !formData.end_date) {
      return;
    }
    setExportingExcel(true);


    try {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = user.name;
      workbook.lastModifiedBy = user.name;
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();
      workbook.views = [
        {
          x: 0, y: 0, width: 10000, height: 20000, firstSheet: 0, activeTab: 1, visibility: "visible",
        },
      ];

      const sheet = workbook.addWorksheet("Appointments Report");

      const headers = [
        "DATAAGENDAMENTO", "DATASERVIÇO", "PERÍODO", "OBS. AGENDAMENTO", "OBS. PET", "OBS. CLIENTE", "DESL.", "TEMPO",
        "INÍCIO", "FIM", "INÍCIO REAL", "FIM REAL", "TUTOR", "CPF", "PET", "RAÇA",
        "SEXO", "ESPÉCIE", "PORTE", "CEP", "ENDEREÇO", "BAIRRO", "NUMERO", "COMPLEMENT",
        "SERVIÇO", "PACOTE", "ADICIONAIS", "VALOR DO SERVICO", "VALOR DO ADICIONAL",
        "DESCONTO", "CUPOM", "VALOR FINAL", "PAGAMENTO", "OBSERVAÇÕES", "GERADOR - TOMADA",
        "CRIADO PELO", "GERADO EM", "VALOR PEDIDO", "TIPO DE TRANSAÇÃO", "ID DA TRANSAÇÃO",
        "PAGARME IDS", "PROVEDOR", "PROFISSIONAL"
      ];

      sheet.addRow(headers).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "8EB7AA" },
      };
      const { start_date, end_date, categories, status, tutor_name } = formData;
      const reportData = await loadFinancialExportableReport({
        filters: { start_date, end_date, categories, status, tutor_name },
      });


      reportData.forEach((appointment: IFinancialReport) => {
        let payment = "";

        if (typeof appointment.appointmentstatus !== 'number') {
          payment = 'sem registro'
        }
        else if (appointment.appointmentstatus >= AppointmentStatusEnum.Pago) {
          payment = "CONCLUIDO";
        } else if (appointment.appointmentstatus === AppointmentStatusEnum["Aguardando pagamento"]) {
          payment = "AGUARDANDO";
        } else if (appointment.appointmentstatus === AppointmentStatusEnum["Aguardando Pagamento Adicional"]) {
          payment = "AGUARDANDO PAGAMENTO ADICIONAL";
        }

        const providers = appointment.provider || 'sem registro'
        const transactionIds = appointment.transactionid || 'sem registro'
        const transactionExternalIds = appointment.externalid || 'sem registro'

        const transactionType = typeof appointment.transactiontype === 'number' ? TransactionTaypeEnum[appointment.transactiontype] : 'sem registro'

        const platform = appointment.platform || "sem registro";
        const row = [
          format(new Date(appointment.created_at), "yyyy-MM-dd") || "sem registro",
          appointment.date!.toString() || "sem registro",
          appointment.period!.toString() || "sem registro",
          appointment.observations || "Sem observação",
          appointment.petobservations || "Sem observações",
          appointment.customerobservations || "Sem observações",
          !!appointment.on_the_way_status_hour && !!appointment.on_local_status_hour
            ? calculateDistanceBetweenHours(
              appointment.on_the_way_status_hour,
              appointment.on_local_status_hour
            ).toString()
            : "",
          appointment.on_the_way_status_hour && appointment.service_completed_status_hour
            ? calculateDistanceBetweenHours(
              appointment.on_the_way_status_hour,
              appointment.service_completed_status_hour
            ).toString()
            : "",
          appointment.hour || "sem registro",
          appointment.service_completed_status_hour || "sem registro",
          appointment.on_the_way_status_hour || "",
          appointment.service_completed_status_hour || "",
          appointment.customername || "sem registro",
          appointment.customercpf || "sem registro",
          appointment.petname || "sem registro",
          appointment.breedname || "sem registro",
          appointment.petgender || "sem registro",
          appointment.speciesname || "sem registro",
          appointment.breedsize
            ? appointment.breedsize
            : calculateSizeByWeigthPet(appointment.speciesname!, appointment.petweight || 0) ||
            "sem registro",
          appointment.cep,
          appointment.street || "sem registro",
          appointment.neighborhood || "sem registro",
          appointment.number || "sem registro",
          appointment.complement || "sem registro",
          appointment?.name || "sem registro",
          appointment?.frequency?.toLowerCase() || "sem registro",
          appointment.appointmentitems?.map((additional) => additional.name).toString() || "sem registro",
          convertToCurrencyFormat((appointment.service_price / 100).toFixed(2)),
          convertToCurrencyFormat(appointment.additionalstotalprice?.toFixed(2)),
          convertToCurrencyFormat(appointment?.discount?.toFixed(2)),
          appointment.couponcode || "Sem cupom",
          convertToCurrencyFormat(appointment.paid_price),
          payment,
          "",
          platform,
          appointment.power_supply
            ? PowerSupplyEnum[appointment.power_supply]
            : "sem registro",
          String(appointment.created_at),
          convertToCurrencyFormat((appointment.transactionamount / 100)?.toFixed(2)),
          transactionType,
          transactionIds,
          transactionExternalIds,
          providers,
          appointment.professionalname,
        ];

        sheet.addRow(row);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.ms-excel;charset=utf-8;",
        });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Appointments_Report_${'selectedFilters.start_date'}_to_${'selectedFilters.end_date'}.xlsx`;
        link.click();
      });
    } catch (error) {
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    } finally {
      setExportingExcel(false);
    }
  };

  return (
    <>
      {exportingExcel && <Loading />}
      <Styles.BackgroundPanel>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Relatórios"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>
        </Styles.PanelHeader>
        <Styles.box>
          <Styles.header>
            <Text
              text="Filtrar agendamentos para o relatório financeiro"
              color={colors.argon.darkBlue}
              fontFamily="Open Sans"
              size={17}
              weight="600"
            />

          </Styles.header>
          <AppointmentFiltersForm onSubmit={handleExportFullData} />
          <Styles.rowButton>
            <Button
              text="Exportar dados"
              type="submit"
              form={AppointmentFiltersFormId}
            />
          </Styles.rowButton>
        </Styles.box>


      </Styles.BackgroundPanel>
    </>
  );
};

export default AppointmentsReport;
