import { useLazyQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from "@unform/web";
import ImageInput from 'components/ImageInput';
import InputRef from "components/InputRef";
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import Text from 'components/Text';
import TextArea from 'components/TextArea';
import { ICouponResponse } from 'pages/Coupon/types';
import { LIST_EVENTS } from 'pages/Events/graphql/EventQuery';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { searchCoupons } from 'services/coupon/couponService';
import colors from 'shared/utils/constants/colors';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { ValidationError } from 'yup';
import PreviewEventCard, { PreviewEventCardProps } from '../../PreviewEventCard';
import * as Styles from '../../constants/styles';
import { ICreateEventFormData } from '../../constants/types';
import { CreateEventFormValidationSchema } from './CreateEventFormValidation';
import CreateAddressForm from 'components/Forms/CreateAddress';

export type ICreateEventFormProps = {
  onSubmit: (payload: ICreateEventFormData, options: { reset: () => void }) => Promise<void>;
}

export const CreateEventFormId = 'create-event-form';


function CreateEventForm({
  onSubmit,
}: ICreateEventFormProps) {
  const createEventFormRef = useRef<FormHandles>(null);

  const [searchInput, setSearchInput] = useState<string>("");
  const [couponsList, setCouponsList] = useState<ICouponResponse[] | []>([]);
  const [previewInfo, setPreviewInfo] = useState<PreviewEventCardProps>();
  const [isValidStartDate, setIsValidStartDate] = useState<boolean>(true);
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);
  
  const [validateEventDate] = useLazyQuery(LIST_EVENTS);

  useEffect(() => {
    const fetchCoupons = async () => {
      const response = await searchCoupons({
        where: {
          wordSearch: searchInput,
          status: true,
        },
        pagination: {
          limit: 20,
          page: 1,
        },
      }) as any;
      const filteredCoupons = response.data.coupons.coupons as ICouponResponse[];
      setCouponsList(filteredCoupons);
    };

    fetchCoupons();
  }, [searchInput]);

  useEffect(() => {
    if (!isValidStartDate) {
      createEventFormRef.current?.setFieldError(
        'event_start_date',
        'Já existe um evento nessa data'
      );
    } else {
      createEventFormRef.current?.setFieldError(
        'event_start_date',
        ''
      );
    }

    if (!isValidEndDate) {
      createEventFormRef.current?.setFieldError(
        'event_end_date',
        'Já existe um evento nessa data'
      );
    } else {
      createEventFormRef.current?.setFieldError(
        'event_end_date',
        ''
      );
    }
  }, [isValidStartDate, isValidEndDate]);

  const handleImageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) {
        setPreviewInfo((previewValue) => ({
          ...previewValue,
          image: '',
        }));
      } else {
        const previewURL = URL.createObjectURL(file);
        setPreviewInfo((previewValue) => ({
          ...previewValue,
          image: previewURL,
        }));
      }
    }, []);

  const validateFields = async (
    payload: ICreateEventFormData,
    options: { reset: () => void }
  ) => {
    try {
      await CreateEventFormValidationSchema.validate(payload, { abortEarly: false });
      onSubmit(payload, options);
    } catch (error) {
      const errors = getValidationErrors(error as ValidationError);
      createEventFormRef.current?.setErrors(errors);
      launchToast("Verifique o preenchimento dos dados", "error");
    }
  };

  const handleValidateDate = (event: FocusEvent<HTMLInputElement>) => {
    try {
      validateEventDate({
        variables: {
          filterParams: {
            event_start_date: {
              filter: "lte",
              date: event.target.value
            },
            event_end_date: {
              filter: "gte",
              date: event.target.value
            },
          },
          paginationParams: {
            limit: 1,
            page: 1,
          },
          orderParams: { },
        },
        onCompleted(data) {
          if (event.target.name === 'event_start_date') {
            setIsValidStartDate(!data.listEvents.data.length);
          } else if (event.target.name === 'event_end_date') {
            setIsValidEndDate(!data.listEvents.data.length);
          }
        },
      });
    } catch (error) {
      console.error(error);
      launchToast(error as string, 'error');
    }
  };

  return (
    <Form onSubmit={validateFields} ref={createEventFormRef} id={CreateEventFormId}>
      <CreateAddressForm formRef={createEventFormRef} />
      <Styles.header>
        <Text
          text="Novo evento"
          color={colors.argon.darkBlue}
          fontFamily="Open Sans"
          size={17}
          weight="600"
        />
      </Styles.header>
      <Styles.row>
        <Styles.field>
          <Label>Nome
            <InputRef
              name="name"
              placeholder="Digite o nome do evento"
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Título
            <InputRef
              name="title"
              placeholder="Digite o título do evento"
              onChange={(e) => setPreviewInfo((previewValue) => ({ ...previewValue, title: e.target.value }))}
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Subtítulo
            <InputRef
              name="subtitle"
              placeholder="Digite um subtítulo"
              size={32}
              onChange={(e) => setPreviewInfo((previewValue) => ({ ...previewValue, subtitle: e.target.value }))}
            />
          </Label>
        </Styles.field>
      </Styles.row>
      <Styles.row>
        <Styles.field>
          <Label>Cupom</Label>
          <Select
            name="coupon_id"
            options={couponsList.map((coupon: ICouponResponse) => ({
              value: coupon.id,
              label: `${coupon.name} - ${coupon.code}`
            })) as { value: string; label: string; }[]}
            onInputChange={setSearchInput}
            placeholder="Nome ou código"
          />
        </Styles.field>
        <Styles.field>
          <Label>Data de início
            <InputRef
              name="event_start_date"
              type="datetime-local"
              onBlur={handleValidateDate}
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Data do término
            <InputRef
              name="event_end_date"
              type="datetime-local"
              onBlur={handleValidateDate}
            />
          </Label>
        </Styles.field>
      </Styles.row>
      <Styles.row>
        <Styles.field>
          <Label>Conteúdo</Label>
          <TextArea
            name="content"
            placeholder="Digite o conteúdo do evento"
            onChange={(e) => setPreviewInfo((previewValue) => ({ ...previewValue, content: e.target.value }))}
          />
        </Styles.field>
        <Styles.field>
          <Label>Imagem</Label>
          <ImageInput
            name="image_url"
            onChange={handleImageChange}
            required
          />
        </Styles.field>
      </Styles.row>
      <PreviewEventCard
        content={previewInfo?.content}
        title={previewInfo?.title}
        subtitle={previewInfo?.subtitle}
        image={previewInfo?.image}
      />
    </Form>
  );
}

export default CreateEventForm;
