import * as Yup from "yup";

export const RegisterSupplierFormValidationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Nome é obrigatório')
		.max(255, 'O nome pode ter no máximo 255 caracteres'),
	cnpj: Yup.string()
		.required('CNPJ é obrigatório')
		.min(14, 'O CNPJ precisa ter no mínimo 14 digitos')
		.max(14, 'O CNPJ pode ter no máximo 14 digitos'),
	email: Yup.string()
		.required('Email é obrigatório')
		.email('Digite um formato de email válido'),
	phoneNumber: Yup.string()
		.required('Telefone é obrigatório')
		.min(11, 'O telefone precisa ter no mínimo 11 digitos')
		.max(11, 'O telefone pode ter no máximo 11 digitos'),
});

export const RegisterSupplierFormId = 'register-supplier-form';
