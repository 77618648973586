import { CreateAddressFormValidationSchema } from "components/Forms/CreateAddress/FormValidationSchema";
import * as Yup from "yup";

export const CreateEventFormValidationSchema = Yup.object().shape({
  address: CreateAddressFormValidationSchema,
  name: Yup.string()
    .required('Nome é obrigatório')
    .max(255, 'O nome pode ter no máximo 255 caracteres'),
  title: Yup.string()
    .required('Título é obrigatório')
    .max(255, 'O título pode ter no máximo 255 caracteres'),
  subtitle: Yup.string()
    .required('Subtitulo é obrigatório')
    .max(255, 'O subtitulo pode ter no máximo 255 caracteres'),
  content: Yup.string()
    .required('Conteúdo é obrigatório')
    .max(255, 'O conteúdo pode ter no máximo 500 caracteres'),
  coupon_id: Yup.string()
    .required('Selecione um cupom'),
  event_start_date: Yup
    .date()
    .required('Data de início é obrigatória'),
  event_end_date: Yup
    .date()
    .min(
      Yup.ref("event_start_date"),
      "Data final deve ser maior que a incial"
    )
    .required('Data do término é obrigatória'),
});
