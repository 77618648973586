import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { formatDate, priceMask, priceUnmask, toBase64 } from './professionalReportUtils';


pdfMake.vfs = pdfFonts;

type Discount = {
  value?: number;
  discount?: string;
  multiplier?: number;
};

type OtherExpense = {
  description: string;
  amount: number;
};

type ProfessionalReportData = {
  grossEarning: number;
  grossPixPayment: number;
  grossCreditCardPayment: number;
  averageTicket: number;
  calendarDays: number;
  operationalDays: number;
  amountOfAppointments: number;
  discounts: Discount[];
  totalDiscount: string;
  earningAfterDiscount: string;
  totalOtherValues: string;
  netEarning: string;
  netPixPayment: string;
  netCreditCardPayment: string;
};

type GeneratePdfParams = {
  logoPath: string;
  payload: {
    professional: {
      label: string;
      value: string;
    };
    from: Date;
    to: Date;
    otherExpenses?: OtherExpense[];
  };
  data: {
    professionalReport: ProfessionalReportData;
  };
};

export const generateProfessionalReportPdf = async ({
  logoPath,
  payload,
  data,
}: GeneratePdfParams) => {
  const { professionalReport } = data;
  const { professional, from, to, otherExpenses = [] } = payload;
  const { discounts = [] } = professionalReport;

  const discountRows = [
    [
      { text: 'Descrição', style: 'tableHeader' },
      { text: 'Valor', style: 'tableHeader' },
      { text: 'Desconto', style: 'tableHeader' },
    ],
    ['Taxa de Adquirência', discounts[2]?.value ?? '', priceMask(discounts[2]?.discount || 0) ?? ''],
    ['Taxa de Adquirência PIX', discounts[3]?.value ?? '', priceMask(discounts[3]?.discount || 0) ?? ''],
    ['Suplementos', '', priceMask(discounts[4]?.discount || 0) ?? ''],
    [
      'Gás',
      discounts[5]?.value && professionalReport.operationalDays
        ? `${priceMask(discounts[5]?.value)} x ${professionalReport.operationalDays}`
        : '',
        priceMask(discounts[5]?.discount || 0) ?? '',
    ],
    [
      'Óleo Gerador',
      discounts[6]?.value && professionalReport.operationalDays
        ? `${priceMask(discounts[6]?.value)} x ${professionalReport.operationalDays}`
        : '',
        priceMask(discounts[6]?.discount || 0) ?? '',
    ],
    [
      'Aluguel do equipamento',
      discounts[7]?.value && professionalReport.operationalDays
        ? `${priceMask(discounts[7]?.value)} x ${professionalReport.operationalDays}`
        : '',
        priceMask(discounts[7]?.discount || 0) ?? '',
    ],
    [
      'Água',
      discounts[8]?.value && professionalReport.amountOfAppointments
        ? `${priceMask(discounts[8]?.value)} x ${professionalReport.amountOfAppointments}`
        : '',
        priceMask(discounts[8]?.discount || 0) ?? '',
    ],
    [
      'Zona Azul',
      discounts[9]?.value && discounts[9]?.multiplier
        ? `${priceMask(discounts[9]?.value)} x ${discounts[9]?.multiplier}`
        : '',
      discounts[9]?.discount ?? '',
    ],
    [
      'Estacionamento',
      discounts[10]?.value && discounts[10]?.multiplier
        ? `${priceMask(discounts[10]?.value)} x ${discounts[10]?.multiplier}`
        : '',
        priceMask(discounts[10]?.discount || 0) ?? '',
    ],
  ];

  const otherExpensesRows = [
    [
      { text: 'Descrição', style: 'tableHeader' },
      { text: 'Valor', style: 'tableHeader' },
    ],
    ...otherExpenses.map((item) => [
      item.description,
      priceMask(item.amount.toFixed(2)),
    ]),
  ];

  const response = await fetch(logoPath);
  const blob = await response.blob();
  const base64Background = await toBase64(blob);

  const docDefinition: any = {
    pageSize: 'A4',
    pageMargins: [30, 100, 30, 40], 
    defaultStyle: {
      fontSize: 10,
    },
    images: {
      background: base64Background,
    },
    background: {
      image: 'background',
      width: 595,
      height: 842,
    },
    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
      subHeader: {
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableHeader: {
        bold: true,
        fillColor: '#eeeeee',
      },
      tableSectionTitle: {
        fillColor: '#F8F8F8',
        bold: true,
      },
    },
    content: [
      {
        text: `Resumo Financeiro: ${professional.label}`,
        style: 'header',
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          {
            text: `Período: ${formatDate(new Date(from))} - ${formatDate(
              new Date(to),
            )}`,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        style: 'tableSectionTitle',
        table: {
          widths: ['*'],
          body: [[{ text: 'Faturamento', alignment: 'center' }]],
        },
        margin: [0, 0, 0, 5],
      },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [{ text: 'Faturamento:', bold: true }, priceMask((professionalReport.grossEarning - priceUnmask(discounts[0]?.discount || '0') - priceUnmask(discounts[1]?.discount || '0')).toFixed(2))],
            [{ text: 'Ticket Médio:', bold: true }, priceMask(professionalReport.averageTicket.toFixed(2))],
            [{ text: 'Dias Corridos:', bold: true }, `${professionalReport.calendarDays}`],
            [{ text: 'Dias de Operação:', bold: true }, `${professionalReport.operationalDays}`],
            [{ text: 'Procedimentos realizados:', bold: true }, `${professionalReport.amountOfAppointments}`],
          ],
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 15],
      },
      {
        text: 'Descontos:',
        style: 'subHeader',
      },
      {
        table: {
          widths: ['*', '*', '*'],
          body: discountRows,
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 10],
      },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [{ text: 'Resultado Após Descontos:', bold: true }, priceMask(professionalReport.earningAfterDiscount)],
          ],
        },
        layout: 'lightHorizontalLines',
        margin: [0, 10, 0, 15],
      },
      {
        text: 'Outros Valores:',
        style: 'subHeader',
      },
      {
        table: {
          widths: ['*', '*'],
          body: otherExpensesRows,
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 10],
      },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [{ text: 'Total de Outros Valores:', bold: true }, priceMask(professionalReport.totalOtherValues)],
            [{ text: 'Resultado Líquido:', bold: true }, priceMask(professionalReport.netEarning)],
          ],
        },
        margin: [0, 0, 0, 10],
        layout: 'lightHorizontalLines',
      },
      {
        table: {
          body: [
            [{ text: '1a Parcela: A Vista', bold: true }, priceMask(professionalReport.netPixPayment)],
            [{ text: '2a Parcela: A Prazo', bold: true }, priceMask(professionalReport.netCreditCardPayment)],
          ],
        },
        layout: 'noBorders',
      },
    ],
  };

  const fileName = `relatorio_${professional.label}.pdf`;
  pdfMake.createPdf(docDefinition).download(fileName);
};
