import Modal from 'components/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './styles';
import Button from "components/Button";
import { gql, useMutation, useQuery } from '@apollo/client';
import Table from "components/Table";
import { useDisclosure } from '@chakra-ui/react';
import { petObservationsColumns } from './conlumns';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import { ReactComponent as EditButton } from "../../../../../assets/svg/generic/edit.svg";
import { ReactComponent as CancelButton } from "../../../../../assets/svg/generic/cancelbutton.svg";
import { launchToast } from 'shared/utils/launchToast';
import NewPetObservationModal from './newObservationModal';
import EditPetObservationModal from './editObservationModal';
import ConfirmationSoftDeletePetObservationModal from './confirmationSoftDeleteModal';
import IAppointment from 'types/IAppointment';
import { IFormattedAppointment } from "../../../utils/formatAppointment";
import { IAction } from "components/Table";
import NewObservationModal from './newObservationModal';
import EditAppointmentObservationModal from './editObservationModal';

export type IAppointmentObservationsModalProps = {
    appointment?: IFormattedAppointment
};

interface IPermanentObsevation {
    id: string
    description: string
    added_by: string
    show_to_customer: boolean
    show_to_professional: boolean
    created_at: string
    pet_id?: string
    user_id?: string
}

export const GET_OBSERVATIONS = gql`
query GetPermanetObservations($input: GetPermanentObservationInput!) {
  getPermanetObservations(input: $input) {
    customer_observations {
      id
      user_id
      description
      added_by
      added_by_id
      show_to_customer
      show_to_professional
      created_at
    }
    pet_observations {
      id
      pet_id
      description
      added_by
      added_by_id
      show_to_customer
      show_to_professional
      created_at
    }
  }
}
`;

const SOFT_DELETE_PET_OBSERVATION = gql`
    mutation SoftDeletePermanentObservation($input: SoftDeleteObservationInput!) {
        softDeletePermanentObservation(input: $input) {
            id
            deleted_at
            deleted_by
        }
    }
`;

const AppointmentObservationsTap: React.FC<IAppointmentObservationsModalProps> = ({ appointment }) => {
    const [observations, setObservations] = useState<IPermanentObsevation[]>([])
    const [selectedObservation, setSelectedObservation] = useState<IPermanentObsevation>([] as any)


    const newObeservationModal = useDisclosure();
    const editObeservationModal = useDisclosure();
    const SoftDeleteObeservationConfirmationModal = useDisclosure();

    const { data, loading, refetch } = useQuery(GET_OBSERVATIONS, {
        variables: {
            input: {
                user_id: appointment?.customer_id,
                pet_id: appointment?.pet_id
            }
        }
    });

    const [softDeletePermanentObservation] = useMutation(SOFT_DELETE_PET_OBSERVATION, {
        refetchQueries: [
            GET_OBSERVATIONS,
            'GetPermanetObservations'
        ],
    });

    const handleConfirmationSoftDelete = useCallback(
        async () => {
            try {
                await softDeletePermanentObservation({
                    variables: {
                        input: {
                            id: selectedObservation.id,
                            observationTo: selectedObservation.pet_id ? "pet" : "customer"
                        }
                    },
                });
                launchToast('Observação deletada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [softDeletePermanentObservation, selectedObservation],
    );

    useEffect(() => {
        if (data) {
            let allObservations = []
            appointment?.observations && allObservations.push({
                description: appointment?.observations,
                show_to_customer: true,
                show_to_professional: true,
                id: '0',
                added_by: 'Agendamento'
            })
            setObservations([
                ...allObservations,
                ...data.getPermanetObservations.pet_observations,
                ...data.getPermanetObservations.customer_observations
            ])
        }
    }, [data,])

    const normalizeDate = (isoString: string) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const handleNewObservation = useCallback(() => {
        newObeservationModal.onOpen()
    }, []);

    const handleSoftDeleteObservation = useCallback((observation) => {
        setSelectedObservation(observation)
        SoftDeleteObeservationConfirmationModal.onOpen()
    }, []);

    const handleEditObservation = useCallback((observation) => {
        setSelectedObservation(observation)
        editObeservationModal.onOpen()
    }, []);


    return (
        <>
            <NewObservationModal
                isOpen={newObeservationModal.isOpen}
                onClose={newObeservationModal.onClose}
                appointment={appointment}
            />
            {selectedObservation && <EditAppointmentObservationModal
                isOpen={editObeservationModal.isOpen}
                onClose={editObeservationModal.onClose}
                observation={selectedObservation}
            />}
            {selectedObservation && <ConfirmationSoftDeletePetObservationModal
                isOpen={SoftDeleteObeservationConfirmationModal.isOpen}
                onClose={SoftDeleteObeservationConfirmationModal.onClose}
                handleConfirm={() => handleConfirmationSoftDelete()}
            />}
            <Styles.header>
                <Text
                    text="Observações"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <Button text="Nova observação" onClick={() => handleNewObservation()} />
            </Styles.header>
            {!loading && observations && observations.length > 0 ? <Table
                headers={petObservationsColumns}
                data={observations.map((observation) => ({
                    id: observation?.id,
                    ObservationTo: observation?.pet_id ? 'PET' : (observation?.user_id ? 'TUTOR' : 'AGENDAMENTO'),
                    description: observation?.description,
                    added_by: observation?.added_by,
                    show_to_customer: observation?.show_to_customer ? "Mostrar" : 'Não mostrar',
                    show_to_professional: observation?.show_to_professional ? "Mostrar" : 'Não mostrar',
                    created_at: observation?.created_at ? normalizeDate(observation.created_at) : '-',
                    actions: observation?.id === '0' ? [] : [
                        {
                            name: "Editar Observação",
                            icon: <EditButton />,
                            action: () => handleEditObservation(observation),
                        },
                        {
                            name: "Deletar Observação",
                            icon: <CancelButton />,
                            action: () => handleSoftDeleteObservation(observation),
                        },
                    ],
                }))}
            /> :
                <Styles.centerRowButton>
                    <Text
                        text="Agendamento sem observações"
                        color={colors.argon.darkBlue}
                        fontFamily="Open Sans"
                        size={18}
                        weight="400"
                        align='center'
                    />
                </Styles.centerRowButton>
            }
        </>
    )
}

export default AppointmentObservationsTap