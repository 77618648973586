import { gql } from "@apollo/client";

export const GET_ACCOUNT_ASSIGNMENTS = gql`
  query AccountAssignments {
    accountAssignments {
      id
      name
    }
  }
`;

export const CREATE_ACCOUNT_ASSIGNMENT = gql`
  mutation CreateAccountAssignment($input: CreateAccountAssignmentInputType!) {
    createAccountAssignment(input: $input) {
      id
      name
    }
  }
`;
