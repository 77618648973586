import React, { useCallback, useEffect, useState } from "react";
import masks, { IMaskOptions } from "../../../../shared/utils/masks";
import { ReactComponent as DeleteButton } from "../../../../assets/svg/generic/delete.svg";
import * as Styles from "./styles";

const perPage = 5;
const maxVisibleButtons = 5;

const CustomerList = ({ customerList, hadleDeleteCustomer }: any) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [numberButtons, setNumberButtons] = useState([] as any);
  const [list, setList] = useState([] as any);
  const [allCustomer, setAllCustomer] = useState([] as any);

  useEffect(() => {
    setAllCustomer(customerList);
  }, [customerList]);

  useEffect(() => {
    let totalPage = Math.ceil(allCustomer.length / perPage);
    setTotalPage(totalPage);
  }, [allCustomer]);



  const prev = () => {
    let pageAux = page;
    pageAux--;

    pageAux < 1 && pageAux++;
    setPage(pageAux);
  };

  const goTo = (page: number) => {
    setPage(page);
  };

  const next = () => {
    let pageAux = page;
    pageAux++;

    const lastPage = page > totalPage;
    lastPage && pageAux--;
    setPage(pageAux);
  };

  const update = useCallback((list: any) => {
    let auxPage = page - 1;
    let start = auxPage * perPage;
    let end = start + perPage;
    setList(list.slice(start, end));

    let maxLeft = page - Math.floor(maxVisibleButtons / 2);
    let maxRight = page + Math.floor(maxVisibleButtons / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = maxVisibleButtons;
    }

    if (maxRight > totalPage) {
      maxLeft = totalPage - (maxVisibleButtons - 1);
      maxRight = totalPage;

      if (maxLeft < 1) maxLeft = 1;
    }

    let buttons = [];

    for (let p = maxLeft; p <= maxRight; p++) {
      buttons.push(p);
    }

    setNumberButtons(buttons);
  },[page, totalPage]);

  useEffect(() => {
    setPage(1);
    update(allCustomer);
  }, [totalPage, allCustomer, update]);

  useEffect(() => {
    update(allCustomer);
  }, [page, allCustomer, update]);

  const applyMask = useCallback((value: string, mask?: IMaskOptions) => {
    return mask ? masks[mask](value) : value;
  }, []);

  const deleteCustomer = (cpf: string) => {
    hadleDeleteCustomer(cpf);
  }

  return (
    <div style={{ marginTop: "30px" }}>
      <div>
        {list.map((c: any) => (
          <>
            <div style={{ display: "flex", alignItems: "flex-end" }} key={c.cpf}>
              <Styles.Text>{c.name}:</Styles.Text>
              <Styles.Text>{applyMask(c.cpf, "cpf")}</Styles.Text>
              <span style={{ cursor: "pointer", marginLeft: "12px" }}>
                <DeleteButton onClick={() => deleteCustomer(c.cpf)}/>
              </span>
            </div>
          </>
        ))}
      </div>
      <div style={{ display: "flex", marginLeft:"50px" }}>
        <Styles.PaginationButtom onClick={() => goTo(1)}>
          &#171;
        </Styles.PaginationButtom>
        <Styles.PaginationButtom onClick={prev}>&lt;</Styles.PaginationButtom>
        {numberButtons.map((b: any) => (
          <Styles.PaginationButtom onClick={() => goTo(b)}>
            {b}
          </Styles.PaginationButtom>
        ))}
        <Styles.PaginationButtom onClick={next}>&gt;</Styles.PaginationButtom>
        <Styles.PaginationButtom onClick={() => goTo(totalPage)}>
          &#187;
        </Styles.PaginationButtom>
      </div>
    </div>
  );
};
export default CustomerList;
