import { FormHandles } from '@unform/core';
import { Form } from '@unform/web'
import { ICreateOutputReasonFormData, ICreateOutputReasonFormProps } from 'pages/StockOutputReasons/constants/types';
import React, { useRef } from 'react'
import { CreateOutputReasonFormId, CreateOutputReasonFormValidationSchema } from './constants';
import { FormRow } from 'pages/StockSuppliers/constants/styles';
import TextInput from 'components/TextInput';
import Select from 'components/Select';
import { typesOfOutputReasons } from 'pages/StockOutputReasons/constants';
import { Label } from 'components/Label/styles';
import { FormField } from 'pages/StockOutputReasons/constants/style';
import { ValidationError } from 'yup';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ApolloError } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';

function CreateOutputReasonForm({
  onSubmit
}: ICreateOutputReasonFormProps) {
  const createOutputReasonFormRef = useRef<FormHandles>(null);

  const validateFields = async (
    payload: ICreateOutputReasonFormData,
    options: { reset: () => void }
  ) => {
    try {
      await CreateOutputReasonFormValidationSchema.validate(payload, { abortEarly: false });
      await onSubmit(payload);
      options.reset();
      createOutputReasonFormRef.current?.setErrors({});
      launchToast("Motivo criado com sucesso!", "success");
    } catch (error) {
			if (error instanceof ValidationError) {
				const errors = getValidationErrors(error);
				createOutputReasonFormRef.current?.setErrors(errors);
			} else if (error instanceof ApolloError) {
				launchToast(error.message, "error");
			} else {
				launchToast("Erro desconhecido. Contate o suporte", "error");
			}
    }
  }

  return (
    <Form
      onSubmit={validateFields}
      ref={createOutputReasonFormRef}
      id={CreateOutputReasonFormId}
    >
      <FormRow>
        <TextInput
          label="motivo"
          name="name"
          placeholder="Digite o motivo"
        />
        <FormField>
          <Label>Tipo</Label>
          <Select
            label
            name="type"
            placeholder="Selecione um tipo"
            options={typesOfOutputReasons}
          />
        </FormField>
      </FormRow>
    </Form>
  )
}

export default CreateOutputReasonForm;
