import { gql } from "@apollo/client";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import api from "../services/api";
import client from "../services/api-graphql";
import { launchToast } from "shared/utils/launchToast";

interface ICustomer {
  name: string;
  email: string;
  cep: string;
  ref_code: string;
  token: string;
}

interface IUser {
  name: string;
  email: string;
  roles: {
    id: string;
    name: string;
  }[];
  birthday: string;
}

interface IAuthState {
  token: string;
  user: IUser;
  customer?: ICustomer;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContext {
  user: IUser;
  customer?: ICustomer;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateCustomerDetails(customer: {
    name?: string;
    email?: string;
    cep?: string;
  }): void;
  passwordRecovery(email: string): void;
  passwordReset(password: string, code: string): void;
  passwordUpdate(
    password: string,
    newPassword: string,
    passwordConfirmation: string
  ): void;
  userRoles: {
    isFinancial: boolean
    isManager: boolean
  };
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem("@zazuu:admin:user:token");
    const user = localStorage.getItem("@zazuu:admin:user");
    const customer = localStorage.getItem("@zazuu:admin:customer");

    if (token && user) {
      if (customer) {
        const customerParsed = JSON.parse(customer);

        api.defaults.headers.authorization = `  ${customerParsed.token}`;

        client.defaultOptions = {
          query: {
            context: {
              headers: {
                authorization: `Bearer ${customerParsed.token}`,
              },
            },
          },
          mutate: {
            context: {
              headers: {
                authorization: `Bearer ${customerParsed.token}`,
              },
            },
          },
        };
        return {
          token: customerParsed.token,
          user: JSON.parse(user),
          customer: customerParsed,
        };
      }

      api.defaults.headers.authorization = `Bearer ${token}`;
      client.defaultOptions = {
        query: {
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        },
        mutate: {
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        },
      };
      return { token, user: JSON.parse(user) };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const results = await client.query({
        query: gql`
          query ($role: String!, $password: String!, $email: String!) {
            signIn(role: $role, password: $password, email: $email) {
              token
              refreshToken
              user {
                id
                name
                cpf
                phone
                birthday
                email
                roles {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          email: email,
          password: password,
          role: "admin",
        },
        fetchPolicy: "no-cache",
      });

      const { token, user } = results.data.signIn;

      localStorage.setItem("@zazuu:admin:user:token", token);

      localStorage.setItem("@zazuu:admin:user", JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      client.defaultOptions = {
        query: {
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        },
        mutate: {
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        },
      };

      setData({
        token,
        user: user,
        customer: undefined,
      });
    } catch (error) {
      if(error = 'ApolloError: Senha incorreta'){
        launchToast("Email/senha incorretos", "error")
      } else{launchToast("Erro no servidor, tente mais tarde", "error")}
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@zazuu:admin:user");
    localStorage.removeItem("@zazuu:admin:user:token");
    localStorage.removeItem("@zazuu:admin:customer");


    setData({} as IAuthState);
  }, []);

  const updateCustomerDetails = useCallback(
    (customer: { name?: string; email?: string; cep?: string }) => {
      setData({
        token: data.token,
        user: data.user,
        customer: data.customer ? { ...data.customer, ...customer } : undefined,
      });
    },
    [setData, data.user, data.token, data.customer]
  );

  const passwordRecovery = useCallback(async (email) => {
    await api.post("/password/forgot", {
      email,
      role: "admin",
    });
  }, []);

  const passwordReset = useCallback(async (password, code) => {
    await api.post("/password/reset", {
      password,
      code,
      role: "admin",
    });
  }, []);

  const passwordUpdate = useCallback(
    async (password, newPassword, passwordConfirmation) => {
      await api.post("/password/change", {
        password,
        newPassword,
        passwordConfirmation,
      });
    },
    []
  );

  useEffect(() => {
    localStorage.setItem("@zazuu:admin:user", JSON.stringify(data.user));
    if (data.customer) {
      localStorage.setItem(
        "@zazuu:admin:customer",
        JSON.stringify(data.customer)
      );
    }
  }, [data]);

  const userRoles = useMemo(() => {
    if (data.user) {
      return {
        isFinancial: data.user.roles.some((role) => role.name === 'financeiro'),
        isManager: data.user.roles.some((role) => role.name === 'gerente'),
      }
    } else {
      return {
        isFinancial: false,
        isManager: false,
      }
    }
  }, [data.user]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        customer: data.customer,
        signIn,
        signOut,
        updateCustomerDetails,
        passwordRecovery,
        passwordReset,
        passwordUpdate,
        userRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
