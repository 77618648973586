import { gql } from "@apollo/client";

export const GET_OUTPUT_REASONS = gql`
  query OutputReasons {
    outputReasons {
      id
      name
      type
    }
  }
`;

export const CREATE_OUTPUT_REASONS = gql`
  mutation CreateOutputReason($input: CreateOutputReasonInputType!) {
    createOutputReason(input: $input) {
      id
      name
      type
      status
      created_at
      updated_at
    }
  }
`;