import * as Yup from "yup";

export const CreateProductFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nome é obrigatório')
    .max(100, 'O nome pode ter no máximo 100 caracteres'),
  description: Yup.string()
    .required('Descrição é obrigatório')
    .max(255, 'A descrição pode ter no máximo 255 caracteres'),
  stockUnit: Yup.string()
    .required('Medida é obrigatória'),
  manufacturerSkuCode: Yup.string()
    .required('Codigo sku é obrigatório')
    .max(100, 'O sku pode ter no máximo 100 caracteres'),
  packagingId: Yup.string()
    .required('Embalagem é obrigatória'),
  productCategoryId: Yup.string()
    .required('Categoria é obrigatória'),
  multiplier: Yup.number()
    .required('Multiplicador é obrigatório')
    .moreThan(0, 'O multiplicador não pode ser igual ou menor que 0'),
  accountAssignmentId: Yup.number()
    .required('Classificação é obrigatória'),
  itemId: Yup.string(),
});

export const CreateProductFormId = 'create-product-form';
