import { gql } from "@apollo/client";

export const CREATE_RECEIPT = gql`
  mutation CreateReceipt($input: CreateReceiptInputType!) {
    createReceipt(input: $input) {
      id
    }
  }
`;

export const GET_RECEIPTS = gql`
  query Receipts(
    $orderParams: ListReceiptsOrderingInputType,
    $filterParams: ListReceiptsInputType,
    $paginationParams:  ReceiptPaginationInputType
  ) {
      receipts(
        orderParams: $orderParams,
        filterParams: $filterParams,
        paginationParams: $paginationParams
      ) {
        data {
          id
          number
          emissionDate
          estimatedDeliveryDate
          status
          supplier {
            name
             id
          }
          receiptProducts {
            id
            quantity
            product {
              id
              stockUnit
              name
            }
          }
          warehouse {
            id
            name
          }
        }
        totalPages
      }
    }
`;

export const GOODS_RECEIPT = gql`
  mutation GoodsReceipt($input: GoodsReceiptInputType!) {
    goodsReceipt(input: $input) {
      id
    }
  }
`;
