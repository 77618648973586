import React, { useCallback, useEffect, useState } from "react";
import { headers } from "./utils/columns";
import Pagination from "../../../components/Pagination";
import Loading from "../../../components/Loading";
import FilterModal from "./FilterModal";
import FilterList from "../../../components/FilterList";
import formatDate from "../../../shared/utils/formatDate";
import { getActualDate } from "../../../shared/utils/getActualDate";
import masks, { IMaskOptions } from "../../../shared/utils/masks";
import { launchToast } from "../../../shared/utils/launchToast";
import { getPetlastAppointments } from "../../../services/petLastAppointment";
import stringOfEnum from "../../../shared/utils/StringOfEnum";
import AppointmentStatusEnum from "../../../shared/utils/enums/AppointmentStatusEnum";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import Table from "components/Table";
import MainContainer from "components/MainContainer";

const actualDate = getActualDate();

const LastAppointment: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isFilterModalVisible, setisFilterModalVisible] = useState(false);
  const [filters, setFilters] = useState([
    { key: "created", searchValue: actualDate },
  ] as any);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sort, setSort] = useState({} as any);
  const [wordSearch, setWordSearch] = useState("");
  const [timer, setTimer] = useState(null as any);

  const applyMask = useCallback((value: string, mask?: IMaskOptions) => {
    return (value = mask ? masks[mask](value) : value);
  }, []);

  const loadCustomers = useCallback(
    async (f: any, wordSearch: string) => {
      setLoading(true);

      let where = {
        wordSearch,
      } as any;

      for (let filter of f) {
        const { key, searchValue } = filter;
        switch (key) {
          case "Bairro":
            where.neighborhood = searchValue;
            break;
          case "Data Início":
            where.from = searchValue;
            break;
          case "Data Fim":
            where.to = searchValue;
            break;
          case "Status":
            where.status = parseFloat(searchValue);
            break;
        }
      }

      let orderBy = [];
      if (!!sort.field) {
        const { field, direction } = sort;
        switch (field) {
          case "date":
            orderBy.push({ date: direction });
            break;
          case "tutor":
            orderBy.push({ tutor_name: direction });
            break;
          case "pet":
            orderBy.push({ pet_name: direction });
            break;
          case "status":
            orderBy.push({ status: direction });
            break;
          default:
            orderBy.push({ date: "desc" });
            break;
        }
      }

      try {
        const results = (await getPetlastAppointments({
          pagination: { limit, page: currentPage },
          where,
          orderBy,
        })) as any;

        const { appointments, totalPages } = results;

        setTotalPage(totalPages);

        let data = [] as any;

        for (let r of appointments) {
          const { id, customer, date, pet, service, status } = r;
          const { name: tutor, phone } = customer.user;
          const { breed } = pet;
          let address = "";

          const { street, neighborhood, number, city, state, cep, complement } =
            r.address;

          address = `${cep} - ${street}, ${number}, ${complement}, ${neighborhood}, ${city}, ${state} `;

          data.push({
            id,
            date: formatDate(date),
            tutor,
            phone: applyMask(phone, "phone"),
            address,
            pet: pet.name,
            breed: breed !== null ? breed.name : "SRD",
            service: service.name,
            status: stringOfEnum(AppointmentStatusEnum, status),
          });
        }
        setData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        launchToast("Ocorreu um erro na requisição", "warning");
      }
    },
    [applyMask, currentPage, limit, sort]
  );

  useEffect(() => {
    setTimer(
      setTimeout(function () {
        loadCustomers(filters, wordSearch);
      }, 500)
    );

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [loadCustomers, wordSearch, currentPage, limit, filters, sort]);

  const onSetPageSize = (pageSize: number) => {
    setLimit(pageSize);
  };

  const handleApplyFilter = async (f: any) => {
    await setFilters(f);
  };

  const handleRemoveFilter = (key: string) => {
    let newFilters = filters.filter((f: any) => f.key !== key);
    setFilters(newFilters);
    loadCustomers(newFilters, wordSearch);
  };

  // eslint-disable-next-line
  const handleSort = (field: string) => {
    let direction = "desc";
    if (!!sort.field && sort.field === field && sort.direction === "desc") {
      direction = "asc";
    }

    setSort({ field, direction });
  };

  return (
    <>
      {loading && <Loading />}
      <MainContainer>
        <PanelHeader
          title="Consultas"
          isFilterModalVisible={true}
          onClick={() => setisFilterModalVisible(true)}
        />
        <Search
          placeholder="Pesquisar por tutor ou pet"
          wordSearch={wordSearch}
          setSearch={setWordSearch}
          isFilterForRegister={true}
          limit={limit}
          onSetPageSize={onSetPageSize}
        />
        {filters.length > 0 && filters[0].key !== "created" && (
          <FilterList filters={filters} removeFilter={handleRemoveFilter} />
        )}
        <Table headers={headers} data={data} />
        <Pagination
          totalPage={totalPage}
          pageIndex={currentPage}
          setPage={setCurrentPage}
        />
      </MainContainer>
      <FilterModal
        visible={isFilterModalVisible}
        onCloseModal={() => setisFilterModalVisible(false)}
        applyFilters={handleApplyFilter}
        reciviedFilter={filters}
      ></FilterModal>
    </>
  );
};
export default LastAppointment;
