import { useQuery } from '@apollo/client';
import Button from 'components/Button';
import Header from 'components/Header';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Table from "components/Table";
import React from 'react';
import { useHistory } from 'react-router-dom';
import { productsTableHeaders } from './constants';
import { GET_PRODUCTS } from './constants/graphQL';
import { IProductsResponse, IProductStockUnitTranslation } from './constants/types';

function StockProducts() {
  const { data, loading: queryLoading } = useQuery<{ products: IProductsResponse }>(GET_PRODUCTS);

  const history = useHistory();

  return (
    <MainContainer>
      <Header title="PRODUTOS" />
      <Button
        text="novo"
        styleContainer={{
          justifyContent: 'flex-end',
          margin: '0px 12px 8px 0px',
        }}
        type="button"
        onClick={() => history.push('/stock-products/create')}
        />
      {queryLoading && <Loading />}
      {data && (
        <Table
          data={data.products.products.map((product) => ({
            ...product,
            stockUnit: IProductStockUnitTranslation[product.stockUnit],
          }))}
          headers={productsTableHeaders}
        />
      )}
    </MainContainer>
  )
}

export default StockProducts;
