import { ApolloError } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import TextInput from 'components/TextInput';
import { ICreateProductCategoryFormData, ICreateProductCategoryFormProps } from 'pages/StockProductCategories/constants/types';
import { FormRow } from 'pages/StockSuppliers/constants/styles';
import React, { useRef } from 'react';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { ValidationError } from 'yup';
import { CreateProductCategoryFormId, CreateProductCategoryFormValidationSchema } from './constants';

function CreateProductCategoryForm({
  onSubmit
}: ICreateProductCategoryFormProps) {
  const createProductCategoryFormRef = useRef<FormHandles>(null);

  const validateFields = async (
    payload: ICreateProductCategoryFormData,
    options: { reset: () => void }
  ) => {
    try {
      await CreateProductCategoryFormValidationSchema.validate(payload, { abortEarly: false });
      await onSubmit(payload);
      options.reset();
      createProductCategoryFormRef.current?.setErrors({});
      launchToast("Categoria criada com sucesso!", "success");
    } catch (error) {
			if (error instanceof ValidationError) {
				const errors = getValidationErrors(error);
				createProductCategoryFormRef.current?.setErrors(errors);
			} else if (error instanceof ApolloError) {
				launchToast(error.message, "error");
			} else {
				launchToast("Erro desconhecido. Contate o suporte", "error");
			}
    }
  }

  return (
    <Form
      onSubmit={validateFields}
      ref={createProductCategoryFormRef}
      id={CreateProductCategoryFormId}
    >
      <FormRow>
        <TextInput
          label="categoria"
          name="name"
          placeholder="Nome da categoria"
        />
      </FormRow>
    </Form>
  )
}

export default CreateProductCategoryForm;
