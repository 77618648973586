import { gql } from "@apollo/client";

export const GET_TRUCKS = gql`
  query Trucks {
    trucks {
      id
      name
      licensePlate
      generatorModel
    }
  }
`;

export const CREATE_TRUCK = gql`
  mutation CreateTruck($input: CreateTruckInputType!) {
    createTruck(input: $input) {
      id
      name
      licensePlate
      generatorModel
    }
  }
`;
