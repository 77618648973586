import { gql } from "@apollo/client";

export const GET_WAREHOUSES = gql`
  query Warehouses {
    warehouses {
      id
      name
      responsibleFor
      address
    }
  }
`;

export const CREATE_WAREHOUSE = gql`
  mutation CreateWarehouse($input: CreateWarehouseInputType!) {
    createWarehouse(input: $input) {
      id
    }
  }
`;

export const GET_PROFESSIONALS = gql`
  query Professionals($filters: FiltersProfessionalInputType) {
    professionals(filters: $filters) {
      id
      user {
        name
        phone
      }
    }
  }
`;
