import Text from 'components/Text';
import React from 'react'
import { HeaderContainer } from './style';
import { MdArrowBack } from "react-icons/md";

export type IHeaderProps = {
  title: string
  hasGoBackButton?: boolean
}

function Header({ title, hasGoBackButton }: IHeaderProps) {
  return (
    <HeaderContainer>
      {hasGoBackButton && <MdArrowBack size={28} />}
      <Text
        weight="600"
        text={title}
        size={24}
      />
    </HeaderContainer>
  )
}

export default Header;
