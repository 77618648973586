import { CreateAddressFormValidationSchema } from "components/Forms/CreateAddress/FormValidationSchema";
import * as Yup from "yup";

export const CreateWarehouseFormValidationSchema = Yup.object().shape({
  address: CreateAddressFormValidationSchema,
  name: Yup.string()
    .required('Classificação é obrigatória')
    .max(100, 'A classificação pode ter no máximo 100 caracteres'),
  userResponsibleId: Yup.string()
    .required('Profissinal é obrigatório'),
});

export const CreateWarehouseFormId = 'create-warehouse-form';
