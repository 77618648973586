import React, { ButtonHTMLAttributes } from 'react';
import Spinner from '../Spinner';

import { Container } from './styles';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  type?: 'submit' | 'reset' | 'button';
  styleContainer?: object;
  loading?: boolean;
  disabled?: boolean;
  icon?: any;
}

const Button: React.FC<IButton> = ({
  text,
  type = 'button',
  loading = false,
  disabled = false,
  styleContainer,
  icon: Icon,
  ...rest
}) => (
  <Container style={styleContainer} disabled={disabled}>
    <button
      type={type}
      {...rest}
      disabled={loading || disabled}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        justifyContent: 'center',
      }}
    >
      {Icon && <Icon size={20} />}
      {loading ? <Spinner size={20} /> : <p>{text}</p>}
    </button>
  </Container>
);

export default Button;
