import * as Yup from "yup";

export const AddProductToReceiptValidationSchema = Yup.object().shape({
	productId: Yup.string()
		.required("Produto é obrigatório"),
	quantity: Yup.number()
		.min(1, "Quantidade não pode ser negativa")
		.required("Quantidade é obrigatória"),
	unityPrice: Yup.number()
		.required("Preço de compra não pode estar zerado"),
	resalePrice: Yup.number()
		.min(
			Yup.ref("unityPrice"),
			"Preço de venda não pode ser menor que o preço de compra"
		)
		.required("Preço de venda não pode estar zerado"),
});

export const AddProductToReceiptFormId = "add-product-to-receipt-form";
