export const productsTableHeaders = [
  { key: "name", name: "nome" },
  { key: "manufacturerSkuCode", name: "sku" },
  { key: "category", name: "categoria" },
  { key: "stockUnit", name: "unidade de medida" },
  { key: "multiplier", name: "multiplicador" },
];

export const stockUnityOptions = [
  { value: "LITER", label: "Litro" },
  { value: "MILLILITRE", label: "Mililitro" },
  { value: "GRAM", label: "Grama" },
  { value: "KILOGRAM", label: "Quilograma" },
  { value: "MILIGRAM", label: "Miligrama" },
  { value: "UNITY", label: "Unidade" },
];
