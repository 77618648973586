const cep = (value: string): string =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");

const cpf = (value: string): string =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

const phone = (value: string): string =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");

const birthday = (value: string): string =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\/\d{4})\d+?$/, "$1");

const cardNumber = (value: string): string =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1$2")
    .replace(/(\d{7})(\d)/, "$1");

const cardValidate = (value: string): string =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})\d+?$/, "$1");

const hours = (value: string): string =>
  value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1:$2");

const money = (value: string): string => {
  const onlyDigits = value
    .split("")
    .filter((s) => /\d/.test(s))
    .join("")
    .padStart(3, "0");
  const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);

  let currency = "BRL";

  value = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency,
  }).format(parseFloat(digitsFloat));

  return value;
};

const cnpj = (value: string): string => 
  value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/^(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}).*/, "$1");

const masks = {
  cep,
  cpf,
  phone,
  birthday,
  cardNumber,
  cardValidate,
  hours,
  money,
  cnpj,
} as const;

export type IMaskOptions = keyof typeof masks;

export const applyMask = (value: string, mask: IMaskOptions) => {
  return masks[mask](value);
};

export const extractNumbersFromMaskedStrings = (value: string): string => {
  return value.replace(/\D/g, '');
};

export default masks;
