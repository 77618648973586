export const outputReasonsTableHeaders = [
  { key: "name", name: "nome" },
  { key: "type", name: "tipo" },
];

export const typesOfOutputReasons = [
  { value: 'NonIdentifyLoss', label: 'Perda não identificada' },
  { value: 'IdentifyLoss', label: 'Perda identificada' },
  { value: 'Promotional', label: 'Promocional' },
  { value: 'FixedAssets', label: 'Ativo fixo imobilizado' },
];