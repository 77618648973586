import React from 'react';
import PanelCard from './PanelCard';
import { panelItems } from './constants';
import { PanelContainer } from './styles';

export default function StockPanel() {
  return (
    <PanelContainer>
      {panelItems.map((item) => (
        <PanelCard
          {...item}
        />
      ))}
    </PanelContainer>
  );
}
