import React, { useEffect, useRef, useState } from 'react';
import * as Styles from './constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from 'components/InputRef';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { ICreateProfessionalReportFormData } from './constants/types';
import {
  CreateProfessionalReportFormValidationSchema,
  extraFildsValidation,
} from './CreateProfessionalReportFormValidations';
import Select from 'components/Select';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import { listOptionProfessional } from './graphql/ProfessionalReportQuery';
import colors from "shared/utils/constants/colors";
import { priceMaskInput, priceUnmask } from './professionalReportUtils';

export type ICreateProfessionalReportFormProps = {
  onSubmit: (
    payload: ICreateProfessionalReportFormData,
    options: { reset: () => void }
  ) => Promise<void>;
};


export type ISelectProfessional = {
  id: string
  user: {
    name: string
  }
};

export const CreateProfessionalReportFormId = 'create-professional-report-form';


interface IExtraField {
  id: number;
  valueName: string;
  reasonName: string;
  operationName: string;
  value?: string;
  reason?: string;
  operation?: string;
}

const CreateProfessionalReportForm: React.FC<ICreateProfessionalReportFormProps> = ({
  onSubmit,
}) => {
  const createProfessionalReportFormRef = useRef<FormHandles>(null);

  const [count, setCount] = useState<number>(0);
  const [companyComissionSelected, setCompanyComissionSelected] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [companyComissionSelectedError, setCompanyComissionSelectedError] =
    useState<string>('');
  const [groomingSupplies, setGroomingSupplies] = useState<string>('R$ 0,00');
  const [gasValue, setGasValue] = useState<string>('R$ 0,00');
  const [waterValue, setWaterValue] = useState<string>('R$ 0,00');
  const [oilValue, setOilValue] = useState<string>('R$ 0,00');
  const [equipmentValue, setEquipmentValue] = useState<string>('R$ 0,00');
  const [blueZoneValue, setBlueZoneValue] = useState<string>('R$ 0,00');
  const [parkingValuer, setParkingValue] = useState<string>('R$ 0,00');
  const [professionalOptions, setProfessionalOptions] = useState([] as any)
  const [searchProfessionalInput, setSearchProfessionalInput] = useState('')
  const [professionalError, setProfessionalError] = useState("");
  const [selectedProfessional, setSelectedProfessional] = useState([] as any)

  const [extraFields, setExtraFields] = useState<IExtraField[]>([]);

  const companyComissionOptions = [
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
  ];

  const handleChangeExtraField = (
    id: number,
    key: 'value' | 'reason' | 'operation',
    newValue: string
  ) => {
    setExtraFields((current) =>
      current.map((field) => {
        if (field.id === id) {
          return {
            ...field,
            [key]: newValue,
          };
        }
        return field;
      })
    );
  };

  const addExtraField = () => {
    setCount((prev) => prev + 1);
    const newFieldId = count + 1;
    setExtraFields((current) => [
      ...current,
      {
        id: newFieldId,
        valueName: `extraValue${newFieldId}`,
        reasonName: `extraReason${newFieldId}`,
        operationName: `extraOperation${newFieldId}`,
        value: '',
        reason: '',
        operation: 'add',
      },
    ]);
  };

  const removeExtraField = (id: number) => {
    setExtraFields((current) => current.filter((field) => field.id !== id));
  };

  const validateFields = async (
    payload: ICreateProfessionalReportFormData,
    options: { reset: () => void }
  ) => {
    try {
      await CreateProfessionalReportFormValidationSchema.validate(
        {
          from: payload.from,
          to: payload.to,
          companyComission: payload.companyComission,
          professional: payload.professional,
          groomingSupplies: payload.groomingSupplies,
          gasValue: payload.gasValue,
          waterValue: payload.waterValue,
          oilValue: payload.oilValue,
          equipmentValue: payload.equipmentValue,
          blueZoneValue: payload.blueZoneValue,
          blueZoneQuantity: payload.blueZoneQuantity,
          parkingValue: payload.parkingValue,
          parkingQuantity: payload.parkingQuantity,
        },
        {
          abortEarly: false,
        }
      );

      for (const field of extraFields) {
        try {
          await extraFildsValidation.validate(
            {
              amount: field.value,
              description: field.reason,
            },
            {
              abortEarly: false,
            }
          );
          createProfessionalReportFormRef.current?.setErrors({});
        } catch (error: any) {
          const valueError = error.errors.some(
            (message: string) => message === 'Valor é obrigatório'
          );
          if (valueError) {
            createProfessionalReportFormRef.current?.setFieldError(
              field.valueName,
              'Valor é obrigatório'
            );
          }
          const descriptionError = error.errors.some(
            (message: string) => message === 'Descrição é obrigatória'
          );
          if (descriptionError) {
            createProfessionalReportFormRef.current?.setFieldError(
              field.reasonName,
              'Descrição é obrigatória'
            );
          }
          throw new Error(`Erro nos campos extras`);
        }
      }

      await onSubmit(
        {
          ...payload,
          professional: selectedProfessional,
          otherExpenses: extraFields.map((extraField) => ({
            amount: extraField.operation === 'add' ? priceUnmask(extraField.value!) : - priceUnmask(extraField.value!),
            description: extraField.reason ?? '',
          })),
        },
        options
      );
      resetForm();
    } catch (error: any) {
      if (error.message.startsWith('Erro nos campos extras')) {
        launchToast('Verifique o preenchimento dos dados', 'error');
      } else {
        (!companyComissionSelected?.value?.length ||
          companyComissionSelected?.value?.length <= 0) &&
          setCompanyComissionSelectedError('Selecione uma opção');
        (!selectedProfessional?.value?.length ||
          selectedProfessional?.value?.length <= 0) &&
          setProfessionalError('Selecione uma opção');
        const errors = getValidationErrors(error as ValidationError);
        createProfessionalReportFormRef.current?.setErrors(errors);
        launchToast('Verifique o preenchimento dos dados', 'error');
      }
    }
  };

  const resetForm = () => {
    createProfessionalReportFormRef.current?.setData({
      from: '',
      to: '',
      groomingSupplies: '0',
      blueZoneQuantity: '0',
      parkingQuantity: '0',
    });
    setCompanyComissionSelected(null);
    setCompanyComissionSelectedError('');
    setProfessionalError('');
    createProfessionalReportFormRef.current?.setErrors({});
    setGroomingSupplies('R$ 0,00');
    setGasValue('R$ 0,00');
    setWaterValue('R$ 0,00');
    setOilValue('R$ 0,00');
    setEquipmentValue('R$ 0,00');
    setBlueZoneValue('R$ 0,00');
    setParkingValue('R$ 0,00');
    setSearchProfessionalInput("")
    setSelectedProfessional([] as any)

    setExtraFields([]);
    setCount(0);
  };

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    let timeoutProfessional: NodeJS.Timeout;
    const fatchProfessional = async () => {
      const professionalList = await listOptionProfessional(searchProfessionalInput)
      setProfessionalOptions(professionalList);
    }
    const delayedFetchData = () => {
      clearTimeout(timeoutProfessional);
      timeoutProfessional = setTimeout(fatchProfessional, 400);
    };

    delayedFetchData();

    return () => clearTimeout(timeoutProfessional);
  }, [searchProfessionalInput]);

  const operationOptions = [
    { value: 'add', label: 'Adicionar' },
    { value: 'remove', label: 'Remover' },
  ];

  return (
    <>
      <Form
        onSubmit={validateFields}
        ref={createProfessionalReportFormRef}
        id={CreateProfessionalReportFormId}
      >
        <Styles.row>
          <Styles.field>
            <Label>Profissional</Label>
            <Select
              name="professional"
              options={professionalOptions?.map((professional: ISelectProfessional) => ({
                value: professional.id,
                label: professional.user.name
              })) as { value: string; label: string; }[]}
              onInputChange={setSearchProfessionalInput}
              error={professionalError}
              placeholder="Busque um profissional"
              onChange={(e) => setSelectedProfessional(e)}
            />
          </Styles.field>
          <Styles.field>
            <Label>Data inicial</Label>
            <InputRef
              containerStyle={{ width: '25rem' }}
              name="from"
              type="date"
            />
          </Styles.field>
          <Styles.field>
            <Label>Data final</Label>
            <InputRef
              containerStyle={{ width: '25rem' }}
              name="to"
              type="date"
            />
          </Styles.field>
        </Styles.row>
        <Styles.row>
          <Styles.field>
            <Label>Comissão Zazuu</Label>
            <Select
              name="companyComission"
              placeholder="Selecione"
              options={companyComissionOptions}
              value={companyComissionSelected}
              error={companyComissionSelectedError}
              onChange={(e: any) => setCompanyComissionSelected(e)}
            />
          </Styles.field>
          <Styles.field>
            <Label>Valor dos suplementos</Label>
            <InputRef
              name="groomingSupplies"
              containerStyle={{ width: '25rem' }}
              value={groomingSupplies}
              onChange={(e: any) => setGroomingSupplies(priceMaskInput(e))}
              placeholder="Digite o valor"
            />
          </Styles.field>
          <Styles.field>
            <Label>Valor do gás</Label>
            <InputRef
              name="gasValue"
              placeholder="Digite o valor"
              containerStyle={{ width: '25rem' }}
              value={gasValue}
              onChange={(e: any) => setGasValue(priceMaskInput(e))}
            />
          </Styles.field>
        </Styles.row>

        <Styles.row>
          <Styles.field>
            <Label>Valor da água</Label>
            <InputRef
              name="waterValue"
              placeholder="Digite o valor"
              containerStyle={{ width: '25rem' }}
              value={waterValue}
              onChange={(e: any) => setWaterValue(priceMaskInput(e))}
            />
          </Styles.field>
          <Styles.field>
            <Label>Valor do óleo do gerador</Label>
            <InputRef
              name="oilValue"
              placeholder="Digite o valor"
              containerStyle={{ width: '25rem' }}
              value={oilValue}
              onChange={(e: any) => setOilValue(priceMaskInput(e))}
            />
          </Styles.field>

          <Styles.field>
            <Label>Valor do aluguel de equipamento</Label>
            <InputRef
              name="equipmentValue"
              placeholder="Digite o valor"
              containerStyle={{ width: '25rem' }}
              value={equipmentValue}
              onChange={(e: any) => setEquipmentValue(priceMaskInput(e))}
            />
          </Styles.field>
        </Styles.row>
        <Styles.row>
          <Styles.field>
            <Label>Valor da Zona Azul</Label>
            <InputRef
              name="blueZoneValue"
              containerStyle={{ width: '25rem' }}
              placeholder="Digite o valor"
              value={blueZoneValue}
              onChange={(e: any) => setBlueZoneValue(priceMaskInput(e))}
            />
          </Styles.field>
          <Styles.field>
            <Label>Zona Azul utilizadas</Label>
            <InputRef
              name="blueZoneQuantity"
              placeholder="Digite a quantidade"
              type="number"
              containerStyle={{ width: '25rem' }}
            />
          </Styles.field>
          <Styles.field>
          </Styles.field>
        </Styles.row>
        <Styles.row>
          <Styles.field>
            <Label>Valor do estacionamento</Label>
            <InputRef
              name="parkingValue"
              containerStyle={{ width: '25rem' }}
              placeholder="Digite o valor"
              value={parkingValuer}
              onChange={(e: any) => setParkingValue(priceMaskInput(e))}
            />
          </Styles.field>
          <Styles.field>
            <Label>Estacionamentos utilizados</Label>
            <InputRef
              name="parkingQuantity"
              placeholder="Digite a quantidade"
              type="number"
              containerStyle={{ width: '25rem' }}
            />
          </Styles.field>
          <Styles.field>
          </Styles.field>
        </Styles.row>

        {extraFields.map((field) => (
          <Styles.row key={field.id}>
            <Styles.field>
              <Label>Cálculo adicional {field.id}</Label>
              <InputRef
                name={field.valueName}
                placeholder={`Digite o valor`}
                containerStyle={{ width: '25rem' }}
                value={field.value || 'R$ 0,00'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeExtraField(field.id, 'value', priceMaskInput(e))
                }
              />
            </Styles.field>

            <Styles.field>
              <Label>Motivo do adicional {field.id}</Label>
              <TextArea
                name={field.reasonName}
                placeholder={`Digite a razão`}
                containerStyle={{ width: '25rem' }}
                value={field.reason}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleChangeExtraField(field.id, 'reason', e.target.value)
                }
              />
            </Styles.field>

            <Styles.field>
              <Label>Operação {field.id}</Label>
              <Select
                name={field.operationName}
                placeholder="Selecione"
                options={operationOptions}
                value={
                  field.operation
                    ? {
                      value: field.operation,
                      label:
                        field.operation === 'add'
                          ? 'Adicionar'
                          : 'Remover',
                    }
                    : null
                }
                onChange={(selectedOption: any) =>
                  handleChangeExtraField(
                    field.id,
                    'operation',
                    selectedOption.value
                  )
                }
              />
            </Styles.field>

            <Styles.field>
              <Label style={{ color: '#fff', boxShadow: 'none' }}>.</Label>
              <Button
                text="Remover"
                onClick={() => removeExtraField(field.id)}
                style={{
                  backgroundColor: `${colors.suport.alert}`,
                  color: '#fff',
                }}
              />
            </Styles.field>
          </Styles.row>
        ))}

        <Styles.rowAddButton>
          <Button text="Adicionar campo"
            onClick={addExtraField}
            style={{
              backgroundColor: `${colors.suport.warning}`,
              color: '#fff',
              marginLeft: 10
            }} />
        </Styles.rowAddButton>
      </Form>
    </>
  );
};

export default CreateProfessionalReportForm;
