import IAppointment from "../../types/IAppointment";
import ITruck from "../../types/ITruck";
import incrementHour from "./incrementHour";

type IProps = {
  appointments: IAppointment[];
  truck?: ITruck;
  start_hour?: string | null;
  reset_hour: boolean;
}

type IPropsVerifyRodizioSP = {
  date: string;
  hour?: string | null;
  licensePlate?: string;
}

const verifyRodizioSP = ({ date, hour, licensePlate }: IPropsVerifyRodizioSP): string | undefined | null => {
  if (!licensePlate || !hour) {
    return hour;
  }

  const plate_last_digit = Number(licensePlate.slice(licensePlate.length - 1, licensePlate.length));

  const day_of_week = new Date(date).getUTCDay();

  if ([1].includes(day_of_week) && [1, 2].includes(plate_last_digit)) {
    if (hour >= '07:00' && hour <= '10:00') {
      return '10:00';
    } else if (hour >= '17:00' && hour <= '19:59') {
      return '20:00';
    } else {
      return hour;
    }
  }
  if ([2].includes(day_of_week) && [3, 4].includes(plate_last_digit)) {
    if (hour >= '07:00' && hour <= '10:00') {
      return '10:00';
    } else if (hour >= '17:00' && hour <= '19:59') {
      return '20:00';
    } else {
      return hour;
    }
  }
  if ([3].includes(day_of_week) && [5, 6].includes(plate_last_digit)) {
    if (hour >= '07:00' && hour <= '10:00') {
      return '10:00';
    } else if (hour >= '17:00' && hour <= '19:59') {
      return '20:00';
    } else {
      return hour;
    }
  }
  if ([4].includes(day_of_week) && [7, 8].includes(plate_last_digit)) {
    if (hour >= '07:00' && hour <= '10:00') {
      return '10:00';
    } else if (hour >= '17:00' && hour <= '19:59') {
      return '20:00';
    } else {
      return hour;
    }
  }
  if ([5].includes(day_of_week) && [9, 0].includes(plate_last_digit)) {
    if (hour >= '07:00' && hour <= '10:00') {
      return '10:00';
    } else if (hour >= '17:00' && hour <= '19:59') {
      return '20:00';
    } else {
      return hour;
    }
  }
  return hour;
}

const updateHourAppointmentsSequence = ({ appointments, truck, start_hour, reset_hour }: IProps): IAppointment[] => {
  let base_hour = reset_hour ? undefined : verifyRodizioSP({
    date: appointments[0].date,
    hour: start_hour,
    licensePlate: truck?.licensePlate
  });
  const appointments_ordered = appointments.map((appointment, index) => {
    if (!!base_hour) {
      appointment.hour = reset_hour ? undefined : base_hour;
      base_hour = incrementHour({
        hour: base_hour,
        minutes: appointment.execution_time
      });
      base_hour = verifyRodizioSP({
        date: appointments[0].date,
        hour: base_hour,
        licensePlate: truck?.licensePlate
      });
    } else {
      appointment.hour = undefined;
    }

    return appointment;
  });
  return appointments_ordered;
};

export default updateHourAppointmentsSequence;
