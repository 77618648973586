import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import Header from 'components/Header';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Table from 'components/Table';
import { CREATE_PRODUCT_CATEGORY, GET_PRODUCT_CATEGORIES } from 'pages/StockProductCategories/constants/graphQL';
import { FormContainer } from 'pages/StockSuppliers/constants/styles';
import React from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { productCategoriesTableHeaders } from './constants';
import { IProductCategoryBase } from './constants/types';
import CreateProductCategoryForm from './forms/createProductCategory';
import { CreateProductCategoryFormId } from './forms/createProductCategory/constants';

function StockProductCategories() {
  const { data, loading: queryLoading } = useQuery<{ productCategories: IProductCategoryBase[] }>(GET_PRODUCT_CATEGORIES);
  const [createProductCategories, { loading }] = useMutation(CREATE_PRODUCT_CATEGORY, {
    refetchQueries: [GET_PRODUCT_CATEGORIES],
  });

  return (
    <MainContainer>
      <Header title="CATEGORIAS DE PRODUTOS" />
      <FormContainer>
        <CreateProductCategoryForm
          onSubmit={(data) => createProductCategories({ variables: { input: data } })}
        />
        <Button
          icon={IoIosAddCircleOutline}
          text="Criar"
          styleContainer={{
            justifyContent: 'flex-end',
            margin: '0px 0px 24px 0px',
            alignSelf: 'end',
            flexGrow: 1,
          }}
          type="submit"
          loading={loading}
          form={CreateProductCategoryFormId}
        />
      </FormContainer>
      {queryLoading && <Loading />}
      {data && (
        <Table
          data={data.productCategories}
          headers={productCategoriesTableHeaders}
        />
      )}
    </MainContainer>
  );
}

export default StockProductCategories;
