import { gql } from "@apollo/client";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../components/Button";
import InputRef from "../../../components/InputRef";
import { Label } from "../../../components/Label/styles";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import Tab from "../../../components/Tab/index";
import { Content, Tabs } from "../../../components/Tab/styles";
import Text from "../../../components/Text";
import client from "../../../services/api-graphql";
import IAddress from "../../../types/IAddress";
import IAppointment from "../../../types/IAppointment";
import IItem from "../../../types/IItem";
import { calculateSizeByWeigthPet } from "../../../shared/utils/calculateSize";
import calculatePriceByPet from "../../../shared/utils/cart/calculatePriceByPet";
import colors from "../../../shared/utils/constants/colors";
import convertToCurrencyFormat from "../../../shared/utils/convertToCurrencyFormat";
import PeriodsEnum from "../../../shared/utils/enums/PeriodsEnum";
import {
  IPayloadUpdateAppointment,
  finishAppointmentByAdmin,
  getItemsById,
  updateAppointment,
  updateAppointmentAdditionals,
  updateAppointmentPriceFix,
} from "./graphql";
import ModalConfirmation from "./ModalConfirmation";
import * as Styles from "./styles";
import processError from "../../../shared/utils/processError";
import { launchToast } from "../../../shared/utils/launchToast";
import AppointmentStatusEnum from "../../../shared/enums/AppointmentStatusEnum";
import { isAfter, parseISO } from "date-fns";
import { addDays } from "date-fns/esm";
import { IFormattedAppointment } from "../utils/formatAppointment";
import { IAction } from "components/Table";
import { useAuth } from "hooks/Auth";
import TextInput from "components/TextInput";
import AppointmentObservationsTap from "./components/AppointmentObservationsModal";

type IProps = {
  visible: boolean;
  onCloseModal: () => void;
  updateAppointmentInList: (appointmnet: IAppointment) => void;
  appointment?: IFormattedAppointment & IAction[];
};

type IFormAppointmentBasicProps = {
  customer_name: string;
  pet_name: string;
  specie: string;
  breed: string;
  pet_size: string;
  period: string;
  date: string;
  address_id: string;
  observations: string;
};

const AppointmentModal = ({
  visible,
  onCloseModal,
  appointment,
  updateAppointmentInList,
}: IProps) => {
  const formRef = useRef<FormHandles>(null);
  const formAdditionalsRef = useRef<FormHandles>(null);
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [itemsWithPrices, setItemsWithPrices] = useState<IItem[]>([]);

  const [additionals, setAdditionals] = useState<IItem[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [editingAdditionals, setEditingAdditionals] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [priceUpdated, setPriceUpdated] = useState(
    appointment?.appointments_items.reduce(
      (total, ai) => total + Number(ai.paid_price),
      0
    ) || 0
  );
  const [adminChangeType, setAdminChangeType] = useState(true);

  const { userRoles } = useAuth();

  if (!appointment) {
    return null;
  }

  const initialValuesBasics = appointment
    ? {
        customer_name: appointment.customer,
        pet_name: appointment.pet.name,
        specie: appointment.pet.specie.name,
        breed: appointment.pet.breed?.name || "sem dado",
        pet_size: appointment.pet.breed?.size ? appointment.pet.breed.size : calculateSizeByWeigthPet({
          specie: appointment.pet.specie.name,
          weight: appointment.pet.weight || 0,
        }),
        period: { value: appointment.period, label: appointment.period },
        date: appointment.date,
        address_id: {
          value: appointment.address.id,
          label: `${appointment.address?.cep || ""} - ${
            appointment.address?.state || ""
          }, ${appointment.address?.city || ""}, ${
            appointment.address?.neighborhood || ""
          }, ${appointment.address?.street || ""}, ${
            appointment.address?.number || ", sn"
          }${appointment.address?.complement ? `, ${appointment.address?.complement}` : ""}
          `,
        },
        observations: appointment.observations,
      }
    : undefined;

  const defaultPriceAdditionals = appointment
    ? appointment.appointments_items.reduce(
        (total, ai) => total + Number(ai.paid_price),
        0
      )
    : 0;

  const initialValuesAdditionals = appointment
    ? {
        category: appointment.service.category.name,
        service: appointment.service.name,
        service_price: convertToCurrencyFormat(appointment.service_price / 100),
        additionals_price: appointment.appointments_items.reduce(
          (total, ai) => total + Number(ai.paid_price),
          0
        ),
        additionals_price_formatted: appointment
          ? convertToCurrencyFormat(
              appointment.appointments_items.reduce(
                (total, ai) => total + Number(ai.paid_price),
                0
              )
            )
          : undefined,
      }
    : undefined;

  useEffect(() => {
    if (!appointment) {
      return;
    }

    const price_all_additionals = additionals.reduce((total, additional) => {
      const original_additional_selected = appointment.appointments_items.find(
        (app_item) => app_item.item.id === additional.id
      );
      if (!!original_additional_selected) {
        return total + Number(original_additional_selected.paid_price);
      }

      const item_recovered = itemsWithPrices.find(
        (item) => item.id === additional.id
      );

      if (!item_recovered) {
        throw Error("Erro ao recuperar item prices");
      }

      const price_new_additional = calculatePriceByPet({
        pet: {
          specie: appointment.pet.specie,
          weight: appointment.pet.weight || 0,
          breed: appointment.pet.breed,
        },
        prices: item_recovered.prices,
      });

      return total + price_new_additional;
    }, 0);

    const price = price_all_additionals;
    formAdditionalsRef.current?.setFieldValue("additionals_price", price);
    formAdditionalsRef.current?.setFieldValue(
      "additionals_price_formatted",
      convertToCurrencyFormat(price)
    );
  }, [additionals, itemsWithPrices, appointment]);

  const handleEditAppointmentBaseData = async (
    data: IFormAppointmentBasicProps
  ) => {
    const modifiedFields = { id: appointment.id } as IPayloadUpdateAppointment;
    Object.entries(data).map((field) => {
      const updateDataKeys = ['address_id', 'date', 'period', 'observations'];
      const key = field[0];
      const value = field[1];
      if (initialValuesBasics && updateDataKeys.includes(key)) {
        const initialFormValue = initialValuesBasics[key as keyof typeof initialValuesBasics];

        let isSameValue = false;

        if (typeof initialFormValue === 'string') {
          isSameValue = initialFormValue === value;
        } else {
          isSameValue = initialFormValue?.value === value;
        }

        if (!isSameValue) {
          modifiedFields[key as keyof IPayloadUpdateAppointment] = value;
        }
      }
    });
    if (!appointment) {
      return;
    }

    try {
      if (Object.keys(modifiedFields).length > 1) {
        const appointment_updated = await updateAppointment(modifiedFields);
        updateAppointmentInList(appointment_updated);
        launchToast("Pedido alterado com sucesso", "success");
      } else {
        launchToast("Altere um campo antes de salvar", "error");
      }
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    }
  };

  const handleConfirmChanges = async () => {
    if (!appointment) {
      return;
    }

    const price_all_additionals = additionals.reduce((total, additional) => {
      const original_additional_selected = appointment.appointments_items.find(
        (app_item) => app_item.item.id === additional.id
      );
      if (!!original_additional_selected) {
        return total + Number(original_additional_selected.paid_price);
      }

      const item_recovered = itemsWithPrices.find(
        (item) => item.id === additional.id
      );

      if (!item_recovered) {
        throw Error("Erro ao recuperar item prices");
      }

      const price_new_additional = calculatePriceByPet({
        pet: {
          specie: appointment.pet.specie,
          weight: appointment.pet.weight || 0,
          breed: appointment.pet.breed,
        },
        prices: item_recovered.prices,
      });

      return total + price_new_additional;
    }, 0);

    setModalConfirmation(true);
    setPriceUpdated(price_all_additionals);
  };

  const handleEditAppointmentItens = async () => {
    if (!appointment) {
      return;
    }
    setModalConfirmation(false);
    try {
      const appointment_updated = await updateAppointmentAdditionals({
        appointment_id: appointment.id,
        additionals_ids: additionals.map((appoint) => appoint.id),
      });
      launchToast("Pedido alterado com sucesso", "success");
      updateAppointmentInList(appointment_updated);
    } catch (error) {
      resetAdditionals();
      launchToast(processError(error, "GRAPHQL").message, "error");
    }

    setEditingAdditionals(false);
  };

  const handleFinishAppointment = async () => {
    if (!appointment) {
      return;
    }
    try {
      const appointment_updated = await finishAppointmentByAdmin(
        appointment.id
      );
      launchToast("Pedido finalizado com sucesso", "success");
      updateAppointmentInList(appointment_updated);
    } catch (error) {
      resetAdditionals();
      launchToast(processError(error, "GRAPHQL").message, "error");
    }
    setEditingAdditionals(false);
  };

  useEffect(() => {
    if (!appointment) {
      return;
    }

    setAdditionals(
      appointment?.appointments_items.map((app_item) => app_item.item) || []
    );
    loadAddresses();
    loadItemsWithPrices();
    // eslint-disable-next-line
  }, [appointment]);

  const resetAdditionals = async () => {
    setAdditionals(
      appointment?.appointments_items.map((app_item) => app_item.item) || []
    );
    setEditingAdditionals(false);
  };

  const loadAddresses = async () => {
    if (!appointment) {
      setAddresses([]);
      return;
    }

    const results = await client.query({
      query: gql`
        query ($userId: String!) {
          AddressesCustomerByAdmin(user_id: $userId) {
            id
            cep
            state
            city
            neighborhood
            street
            number
            complement
          }
        }
      `,
      variables: {
        userId: appointment.customer_id,
      },
    });
    setAddresses(results.data.AddressesCustomerByAdmin);
  };

  const loadItemsWithPrices = async () => {
    if (!appointment) {
      return;
    }

    const items_ids = appointment.service.groups.reduce<string[]>(
      (ids, group) => {
        const items_ids_by_group = group.items.map((item) => item.id);
        return ids.concat(items_ids_by_group);
      },
      []
    );

    const results = await getItemsById(items_ids);
    setItemsWithPrices(results.data.items);
  };

  const handleChangeAdditional = (e: any, additional_id: string) => {
    e.preventDefault();
    const additional_already_checked = !!additionals.find(
      (addit) => addit.id === additional_id
    );
    if (additional_already_checked) {
      setAdditionals(additionals.filter((addit) => addit.id !== additional_id));
    } else {
      setAdditionals([
        ...additionals,
        { id: additional_id, name: "new item", prices: [] },
      ]);
    }
  };

  const handleApplyAdminChanges = async (e: {
    admin_change: number;
    reason: string;
  }) => {
    if (!appointment?.id) {
      launchToast("Agendamento id vazio contate o suporte", "error");
      return;
    }
    try {
      const appointment_updated = await updateAppointmentPriceFix({
        appointment_id: appointment?.id,
        price: (adminChangeType ? 1 : -1) * e.admin_change,
        reason: e.reason,
      });
      updateAppointmentInList(appointment_updated);
      launchToast("Correção de preço aplicada com sucesso", "success");
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    }
  };

  const handleFinishAppointmentButton = (appointmentStatus: number) => {
    if (appointmentStatus === AppointmentStatusEnum["Atendimento finalizado"]) {
      return true;
    }

    return userRoles.isFinancial ? false : true;
  };

  function checkAppointmentStatus(appointment: IFormattedAppointment) {
    const validStatuses = [
      AppointmentStatusEnum["A caminho"],
      AppointmentStatusEnum["Atendimento finalizado"],
      AppointmentStatusEnum["Cancelado"],
      AppointmentStatusEnum["Em andamento"],
      AppointmentStatusEnum["No local"],
      AppointmentStatusEnum["Procedimento finalizado"],
      AppointmentStatusEnum["Pago"],
    ];

    if (userRoles.isFinancial) {
      return false;
    }

    return validStatuses.includes(appointment.status);
  }

  const checkIsFinancialUserOrBeforeAppointmentDate = (date: string, status: number) => {    
    if (userRoles.isFinancial) {
      return true;
    }

    const validStatuses = [
      AppointmentStatusEnum['Aguardando Pagamento Adicional'],
      AppointmentStatusEnum['Cancelado'],
      AppointmentStatusEnum['Aguardando Agendar'],
      AppointmentStatusEnum['Aguardando pagamento'],
      AppointmentStatusEnum.Pago,
      AppointmentStatusEnum['A caminho'],
      AppointmentStatusEnum['No local'],
      AppointmentStatusEnum['Em andamento']
    ]

    const isValidStatus = validStatuses.includes(status)

    if(isValidStatus === false)  {
      return false
    }

    const isAppointmentAfterToday = isAfter(
      parseISO(date),
      addDays(new Date(), -1)
    );
    return isAppointmentAfterToday;
  };

  const isFinishedAppointment = useMemo(() => appointment.status === AppointmentStatusEnum["Atendimento finalizado"], [appointment]);

  return (
    <Modal visible={visible} closeModal={() => onCloseModal()}>
      <Styles.header>
        <Text
          text="Detalhes do agendamento"
          color={colors.argon.darkBlue}
          fontFamily="Open Sans"
          size={17}
          weight="600"
        />

        <Text
          text="X"
          color={colors.gray.dark01}
          fontFamily="Open Sans"
          size={17}
          weight="600"
          onTextClick={() => onCloseModal()}
        />
      </Styles.header>

      <div style={{ width: "100%" }}>
        <Tabs>
          <Tab
            activeTab={() => setActiveTab(0)}
            active={activeTab === 0}
            id={0}
            title="informações do agendamento"
          />
          <Tab
            activeTab={() => setActiveTab(1)}
            active={activeTab === 1}
            id={1}
            title="adicionais"
          />
          <Tab
            activeTab={() => setActiveTab(2)}
            active={activeTab === 2}
            id={1}
            title="admin"
          />
          <Tab
            activeTab={() => setActiveTab(3)}
            active={activeTab === 3}
            id={1}
            title="Observações"
          />
        </Tabs>
        <Styles.line></Styles.line>

        <Styles.FormDiv>
          <Form
            onSubmit={(e) => handleEditAppointmentBaseData(e)}
            ref={formRef}
            initialData={initialValuesBasics}
          >
            <Content active={activeTab === 0}>
              <Styles.row>
                <TextInput
                  label="tutor"
                  placeholder="Nome do tutor"
                  name="customer_name"
                  type="text"
                  disabled
                />
                <TextInput
                  label="pet"
                  placeholder="Digite o nome do pet"
                  name="pet_name"
                  type="text"
                  disabled
                />
                <TextInput
                  label="espécie"
                  placeholder="Digite a especie"
                  name="specie"
                  type="text"
                  disabled
                />
              </Styles.row>
              <Styles.row>
                <TextInput
                  label="raça"
                  placeholder="Digite a raça"
                  name="breed"
                  type="text"
                  disabled
                />
                <TextInput
                  label="porte"
                  placeholder="Digite o porte"
                  name="pet_size"
                  type="text"
                  disabled
                />
                <Styles.field>
                  <Label>período</Label>
                  <Select
                    name="period"
                    containerStyle={{ width: "33rem" }}
                    placeholder="Período"
                    options={[
                      { label: PeriodsEnum.first, value: PeriodsEnum.first },
                      { label: PeriodsEnum.second, value: PeriodsEnum.second },
                      { label: PeriodsEnum.third, value: PeriodsEnum.third },
                    ]}
                    isDisabled={isFinishedAppointment ? !userRoles.isFinancial : false}
                  />
                </Styles.field>
              </Styles.row>
              <Styles.row>
                <TextInput
                  label="data"
                  name="date"
                  type="date"
                  disabled={isFinishedAppointment ? !userRoles.isFinancial : false}
                />
                <TextInput
                  label="observações (sempre aparecerá para o cliente)"
                  placeholder="Ex: não usar perfume"
                  name="observations"
                  type="text"
                />
              </Styles.row>
              <Styles.row>
                <Styles.field>
                  <Label>endereço</Label>
                  <Select
                    name="address_id"
                    containerStyle={{ width: "100%" }}
                    style={{ width: "100%" }}
                    placeholder="Endereço"
                    options={addresses.map((address) => ({
                      value: address.id,
                      label: `${address?.cep || ""} - ${
                        address?.state || ""
                      }, ${address?.city || ""}, ${
                        address?.neighborhood || ""
                      }, ${address?.street || ""}, ${
                        address?.number || ", sn"
                      } ${address?.complement || ""}`,
                    }))}
                    isDisabled={isFinishedAppointment ? !userRoles.isFinancial : false}
                  />
                </Styles.field>
              </Styles.row>
              <Styles.rowButton margin={"70"}>
                <Styles.WhiteButton
                  onClick={() => onCloseModal()}
                  type="button"
                >
                  Descartar alterações
                </Styles.WhiteButton>
                <Button
                  text="Salvar"
                  type="submit"
                  styleContainer={{ minWith: "100px" }}
                />
              </Styles.rowButton>
            </Content>
          </Form>
          <Form
            ref={formAdditionalsRef}
            onSubmit={() => handleConfirmChanges()}
            initialData={initialValuesAdditionals}
          >
            <Content active={activeTab === 1}>
              <Styles.row>
                <Styles.field>
                  <Label>categoria</Label>
                  <InputRef
                    placeholder="Digite a categoria"
                    name="category"
                    containerStyle={{ width: "33rem" }}
                    type="text"
                    disabled={true}
                  />
                </Styles.field>

                <Styles.field>
                  <Label>serviço</Label>
                  <InputRef
                    name="service"
                    placeholder="valor do serviço"
                    containerStyle={{ width: "33rem" }}
                    type="text"
                    disabled={true}
                  />
                </Styles.field>
                <Styles.field>
                  <Label>valor do serviço</Label>
                  <InputRef
                    placeholder="valor do serviço"
                    name="service_price"
                    containerStyle={{ width: "33rem" }}
                    type="text"
                    disabled={true}
                  />
                </Styles.field>
              </Styles.row>
              <Styles.row>
                <Styles.field>
                  <Label>valor dos adicionais</Label>
                  <InputRef
                    placeholder="valor dos adicionais"
                    name="additionals_price_formatted"
                    containerStyle={{ width: "33rem" }}
                    type="text"
                    value={convertToCurrencyFormat(priceUpdated)}
                    disabled={true}
                  />
                </Styles.field>
              </Styles.row>
              <Styles.AdditionalsSection>
                <Text
                  text="ITENS DO SERVIÇO"
                  color={colors.gray.dark02}
                  fontFamily="Open Sans"
                  size={16}
                  marginBottom={16}
                  weight="600"
                />
                <Styles.AdditionalsSectionGroups>
                  {appointment.service.groups.map((group) => (
                    <Styles.Group key={group.id}>
                      <Styles.GroupHeader>
                        <Text
                          text={group.name}
                          color={colors.argon.textColorGray}
                          fontFamily="Open Sans"
                          size={16}
                          weight="600"
                          whiteSpace="nowrap"
                          align="left"
                        />
                      </Styles.GroupHeader>
                      <Styles.GroupItens>
                        {group.items.map((item) => {
                          const selected = !!additionals.find(
                            (addit) => addit.id === item.id
                          );
                          const blocked_by_specie = itemsWithPrices
                            .find((iwp) => iwp.id === item.id)
                            ?.prices.every(
                              (price) =>
                                price.specie_id !== appointment.pet.specie.id
                            );
                          return (
                            <Styles.GroupItem key={item.id}>
                              <Styles.GroupItemCheck
                                disabled={
                                  !editingAdditionals || blocked_by_specie
                                }
                                onClick={(e) =>
                                  handleChangeAdditional(e, item.id)
                                }
                                selected={selected}
                              >
                                {(selected || editingAdditionals) &&
                                  !blocked_by_specie && (
                                    <svg
                                      width="10"
                                      height="7"
                                      viewBox="0 0 10 7"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.32478 1.58705C9.32478 1.44754 9.26897 1.30804 9.16853 1.20759L8.4096 0.44866C8.30915 0.348214 8.16964 0.29241 8.03013 0.29241C7.89063 0.29241 7.75112 0.348214 7.65067 0.44866L3.98996 4.11496L2.34933 2.46875C2.24888 2.3683 2.10938 2.3125 1.96987 2.3125C1.83036 2.3125 1.69085 2.3683 1.5904 2.46875L0.831473 3.22768C0.731027 3.32812 0.675223 3.46763 0.675223 3.60714C0.675223 3.74665 0.731027 3.88616 0.831473 3.98661L2.85156 6.0067L3.61049 6.76562C3.71094 6.86607 3.85045 6.92188 3.98996 6.92188C4.12946 6.92188 4.26897 6.86607 4.36942 6.76562L5.12835 6.0067L9.16853 1.96652C9.26897 1.86607 9.32478 1.72656 9.32478 1.58705Z"
                                        fill="white"
                                      />
                                    </svg>
                                  )}
                              </Styles.GroupItemCheck>
                              <Text
                                text={item.name}
                                color={colors.gray.dark02}
                                fontFamily="Open Sans"
                                size={16}
                                weight="400"
                                align="left"
                                marginLeft={12}
                              />
                            </Styles.GroupItem>
                          );
                        })}
                      </Styles.GroupItens>
                    </Styles.Group>
                  ))}
                </Styles.AdditionalsSectionGroups>
              </Styles.AdditionalsSection>

              {!editingAdditionals &&
                checkIsFinancialUserOrBeforeAppointmentDate(
                  appointment.date,
                  appointment.status
                ) && (
                  <Styles.rowButton margin={"89"}>
                    <Styles.EditButton
                      onClick={() => setEditingAdditionals(true)}
                    >
                      Editar
                    </Styles.EditButton>
                  </Styles.rowButton>
                )}

              {editingAdditionals && (
                <Styles.rowButton margin={"70"}>
                  <Styles.WhiteButton onClick={() => resetAdditionals()}>
                    Descartar alterações
                  </Styles.WhiteButton>
                  <Button
                    text="Salvar"
                    type="submit"
                    styleContainer={{ minWith: "100px" }}
                  />
                </Styles.rowButton>
              )}
            </Content>
          </Form>
          <Form
            ref={formAdditionalsRef}
            onSubmit={(e) => handleApplyAdminChanges(e)}
          >
            <Content active={activeTab === 2}>
              <Styles.row>
                <Styles.field>
                  <Label>ajuste admin</Label>
                  <InputRef
                    placeholder="Digite o valor"
                    name="admin_change"
                    containerStyle={{ width: "33rem" }}
                    type="number"
                    defaultValue={appointment.price_fix / 100}
                    step="any"
                    disabled={checkAppointmentStatus(appointment)}
                  />
                </Styles.field>
                <Styles.field>
                  <Styles.AdminChangeTypeContainer>
                    <Styles.AdminChangeTypeDecrease
                      actived={adminChangeType}
                      onClick={() => setAdminChangeType(false)}
                      type="button"
                      disabled={checkAppointmentStatus(appointment)}
                    >
                      decréscimo
                    </Styles.AdminChangeTypeDecrease>
                    <Styles.AdminChangeTypeIncrease
                      actived={adminChangeType}
                      onClick={() => setAdminChangeType(true)}
                      type="button"
                      disabled={checkAppointmentStatus(appointment)}
                    >
                      acréscimo
                    </Styles.AdminChangeTypeIncrease>
                  </Styles.AdminChangeTypeContainer>
                </Styles.field>
              </Styles.row>
              <Styles.row>
                <Styles.field>
                  <Label>motivo da alteração</Label>
                  <InputRef
                    placeholder="motivo"
                    name="reason"
                    disabled={checkAppointmentStatus(appointment)}
                  />
                </Styles.field>
              </Styles.row>
              {checkIsFinancialUserOrBeforeAppointmentDate(
                appointment.date,
                appointment.status
              ) && (
                <Styles.row style={{ justifyContent: "space-between" }}>
                  <Styles.rowButton style={{ marginLeft: 0 }}>
                    <Button
                      text="Finalizar atendimento"
                      type="button"
                      styleContainer={{ minWith: "100px" }}
                      disabled={handleFinishAppointmentButton(
                        appointment.status
                      )}
                      onClick={handleFinishAppointment}
                    />
                  </Styles.rowButton>
                  <Styles.rowButton
                    style={{ justifyContent: "flex-end", marginRight: "14px" }}
                  >
                    <Button
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                      disabled={checkAppointmentStatus(appointment)}
                    />
                  </Styles.rowButton>
                </Styles.row>
              )}
              <p>
                <strong>Valor atual:</strong>{" "}
                {appointment.price_fix.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </Content>
            <Content active={activeTab === 3}>
                <AppointmentObservationsTap appointment={appointment}/>
            </Content>
          </Form>
        </Styles.FormDiv>
      </div>
      <ModalConfirmation
        visible={modalConfirmation}
        onCloseModal={() => setModalConfirmation(false)}
        handleConfirm={() => handleEditAppointmentItens()}
        price_default={defaultPriceAdditionals}
        price_updated={priceUpdated}
      />
    </Modal>
  );
};
export default AppointmentModal;
