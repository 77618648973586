import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Styles from '../../constants/styles';
import { Label } from "components/Label/styles";
import Select from "components/Select";
import { listOptionProducts, listOptionProfessionals, listOptionTrucks } from "pages/Moviment/graphql/MovimentQuery";
import { IProductMoviment, ITruckMoviment, IprofessionalMoviment } from "pages/Moviment/constants/types";


export type IFiltersMovimentsFormProps = {
    onSubmit: (payload: IFilterMoviments, { reset }: any) => Promise<void>
}

export interface IFilterMoviments {
    [key: string]: string | number | boolean
}

export const FiltersMovimentsFormId = 'filters-moviment--form';


const FiltersMovimentsForm = ({ onSubmit }: IFiltersMovimentsFormProps) => {
    const filtersMovimentsformRef = useRef<FormHandles>(null);

    const [searchProductInput, setSearchProductInput] = useState('')
    const [productOptions, setProductOptions] = useState([] as any)
    const [searchProfessionalInput, setSearchProfessionalInput] = useState('')
    const [professionalOptions, setProfessionalOptions] = useState([] as any)
    const [searchTruckInput, setSearchTruckInput] = useState('')
    const [truckOptions, setTruckOptions] = useState([] as any)

    useEffect(() => {
        let timeoutProduct: NodeJS.Timeout;
        const fatchProducts = async () => {
            const productList = await listOptionProducts(searchProductInput)
            setProductOptions(productList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutProduct);
            timeoutProduct = setTimeout(fatchProducts, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutProduct);
      }, [searchProductInput]);

      useEffect(() => {
        let timeoutProfessional: NodeJS.Timeout;
        const fatchProfessionals = async () => {
            const professionalList = await listOptionProfessionals(searchProfessionalInput)
            setProfessionalOptions(professionalList)
        }
        fatchProfessionals
        const delayedFetchData = () => {
            clearTimeout(timeoutProfessional);
            timeoutProfessional = setTimeout(fatchProfessionals, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutProfessional);
    }, [searchProfessionalInput]);

    useEffect(() => {
        let timeoutTruck: NodeJS.Timeout;
        const fatchTrucks = async () => {
            const truckList = await listOptionTrucks(searchTruckInput)
            setTruckOptions(truckList)
        }
        fatchTrucks
        const delayedFetchData = () => {
            clearTimeout(timeoutTruck);
            timeoutTruck = setTimeout(fatchTrucks, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutTruck);
    }, [searchTruckInput]);

    const resetFilters = () => {
        setSearchProductInput("")
        setSearchProfessionalInput("")
        setSearchTruckInput("")
        filtersMovimentsformRef.current?.setData({
            productId: '',
        });
    }

    const validateFields = async (
        payload: IFilterMoviments,
        options: { reset: () => void }
    ) => {
        const nonEmptyKeys = Object.keys(payload).filter(key => payload[key] !== "");
        const filteredPayload: IFilterMoviments = nonEmptyKeys.reduce(
            (result, key) => {
                result[key] = payload[key];
                return result;
            },
            {} as IFilterMoviments
        );
        onSubmit(filteredPayload, options);

        resetFilters()
    };

    return (
        <Form onSubmit={validateFields} ref={filtersMovimentsformRef} id={FiltersMovimentsFormId} >
            <Styles.row>
                <Styles.field>
                    <Label>Produtos</Label>
                    <Select
                        name="productId"
                        options={productOptions?.map((product:  IProductMoviment) => ({
                            value: product.id,
                            label: product.name
                        })) as { value: string; label: string; }[]}
                        onInputChange={setSearchProductInput}
                        placeholder="Busque um produto"
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Destinatario</Label>
                        <Select
                            name="professionalId"
                            options={ 
                                professionalOptions?.map((professional:  IprofessionalMoviment) => ({
                                    ...professional,
                                    label: professional.user.name,
                                    value: professional.user.id
                                })) 
                            }
                            onInputChange={setSearchProfessionalInput}
                            placeholder={"Busque um professional"}
                        />
                </Styles.field>
                <Styles.field>
                    <Label>Truck</Label>
                    <Select
                        name="truckId"
                        options={ truckOptions?.map((recipient: ITruckMoviment) => ({
                            ...recipient,
                            label: recipient?.name,
                            value: recipient?.id
                        }))}                                
                        onInputChange={setSearchTruckInput}
                      placeholder={"Buscar Truck"}
                    />
                </Styles.field>
            </Styles.row>
        </Form>
    )
}

export default FiltersMovimentsForm