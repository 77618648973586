import * as Yup from "yup";

export const EditProductQuantityValidationSchema = Yup.object().shape({
	receipt: Yup.object().shape({
		products: Yup.array(Yup.object().shape({
			name: Yup.string()
				.required("Produto é obrigatório"),
			quantity: Yup.number()
				.typeError("Quantidade precisa ser um número")
				.min(0, "Quantidade não pode ser negativa")
				.required("Quantidade é obrigatória"),
			stockUnit: Yup.string()
				.required('Medida é obrigatória'),
		}))
	}),
});

export const UpdateReceiptFormId = 'update-receipt-form';
