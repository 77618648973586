import * as Yup from "yup";

export const CreateOutputReasonFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Motivo é obrigatório')
    .max(100, 'O motivo pode ter no máximo 100 caracteres'),
  type: Yup.string()
    .oneOf(['NonIdentifyLoss', 'IdentifyLoss', 'Promotional', 'FixedAssets'])
});

export const CreateOutputReasonFormId = 'create-output-reason-form';
