import { useQuery } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/react";
import FilterList from "components/FilterList";
import Pagination from "components/Pagination";
import { PanelHeader } from "components/PanelHeader";
import Table from "components/Table";
import { format, parseISO } from "date-fns";
import { APIFiltersReceipts } from "pages/Receipts/constants/types";
import React, { useEffect, useState } from "react";
import { launchToast } from "shared/utils/launchToast";
import { columnMovimentssHeaders } from "./constants/conlumns";
import { IFilterMovimentsFormData, IMovimentLog } from "./constants/types";
import { LIST_MOVIMENT, getUser } from "./graphql/MovimentQuery";
import CreateMovimentModal from "./modals/CreateMovimentModal";
import FiltersMovimentsModal from "./modals/FiltersMovimentsModal";
import * as Styles from "./styles";

const Moviment: React.FC = () => {

    const [paginationLimit, setPaginationLimit] = useState<number>(10);
    // const [searchInput, setSearchInput] = useState<string>("");
    // const [receiptSelected, setReceiptSelected] = useState<IReceipt | null>(null);
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [listMoviments, setListMoviments] = useState([] as any);
    // const [idToToggleCampaign, setIdToToggleCampaign] = useState<number>(1)

    const createMovimentModalControl = useDisclosure();
    const filterMovimentModalControl = useDisclosure();

    // const handleWordSearch = (receipt: string) => {
    //     setCurrentPage(1)
    //     setSearchInput(receipt)
    // };

    // const normalizeLabel = (roleLabel: string) => {
    //     const normalizationMap: Record<string, string> = {
    //         "DONATE": "Doação",
    //     };
    //     return normalizationMap[roleLabel] || roleLabel;
    // };

    // const handleReceiptUpdate = useCallback(
    //     async (receipt: IReceipt) => {
    //         setReceiptSelected(receipt);
    //         updateReceiptsModalControl.onOpen();
    //     },
    //     [updateReceiptsModalControl]
    // )

    // const handleCampaignReport = useCallback(
    //     async (campaign: ICampaign) => {
    //         setCampaignSelected(campaign);
    //         reportCampaignModalControl.onOpen();
    //     },
    //     [reportCampaignModalControl]
    // )

    // const [toggleCampaign, { loading: toggleCampaingLoad }] = useMutation(TOGGLE_CAMPAIGN, {
    //     refetchQueries: [
    //         LIST_CAMPAIGNS,
    //         'ListCampaign',
    //     ],
    // });

    // const handleCampaignToggle = useCallback(
    //     async () => {
    //         try {
    //             const variables: IToggleCampaign = {
    //                 id: idToToggleCampaign,
    //             }
    //             await toggleCampaign({
    //                 variables,
    //             });
    //             launchToast('Status alterado com sucesso!', "success");
    //         } catch (error: any) {
    //             launchToast(error.message, "error");
    //         }
    //     }, [toggleCampaign, idToToggleCampaign]
    // );

    // const handleToggleConfirmation = (campaignId: number) => {
    //     setIdToToggleCampaign(campaignId);
    //     toggleCampaignModalControl.onOpen();
    // }


    const { data, refetch } = useQuery(LIST_MOVIMENT, {
        variables: {
            input: {
                pagination:{
                    limit: paginationLimit,
                    page: currentPage
                  },
            }
        },
    });

    const movedByName = async (id: string) =>{
        const user = await getUser(id)
        const name = user.name
        return name
    }

    useEffect(() => {
        const refactorData = async (data: any) => {
            const updatedMoviments = await Promise.all(data.map(async (moviment: IMovimentLog) => {
                const name = await movedByName(moviment.moved_by);
                return { ...moviment, moved_by: name };
            }));
    
            setListMoviments(updatedMoviments);
    
        }
    
        if (data?.stockActionLogs && data.stockActionLogs.logs) {
            refactorData(data.stockActionLogs.logs);
        }
    }, [data]);

    useEffect(() => {
        try {
            const filterParams = filters.reduce((acc, filter: { key: string, value: string }) => {
                acc[filter.key as keyof IFilterMovimentsFormData] = filter.value;
                return acc;
            }, {} as IFilterMovimentsFormData);
            refetch({
                input: {
                    ...filterParams,
                    pagination: {
                        limit: paginationLimit,
                        page: currentPage
                    }
                }
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    }, [paginationLimit, currentPage])

    const handleAddFilter = (params: any) => {
        setCurrentPage(1);
        refetch(params);
    };

    const normalizeWord = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "Numero": "number",
            "CNPJ": "cnpj",
            "Depois de": "startDate",
            "ADD": "Adicionar",
            "REMOVE": "Remover",
            "LITER": "Litros",
            "MILLILITRE": "Mililitros",
            "GRAM": "Gramas",
            "KILOGRAM": "Quilogramas",
            "MILIGRAM": "Miligramas",
            "UNITY": "Unidade"
        };
        return normalizationMap[roleLabel] || roleLabel;
    };

    const handleRemoveFilter = (key: string) => {
        try {
            const newFilters = filters.filter((filter: any) => filter.key !== key);
            setFilters(newFilters);
            const filterParams = newFilters.reduce((acc, filter: { key: string, value: string }) => {
                const normalizeKey = normalizeWord(filter.key) 
                acc[normalizeKey as keyof APIFiltersReceipts] = filter.value as any;
                return acc;
            }, {} as APIFiltersReceipts);
            setCurrentPage(1);
            refetch({
                input: {
                    ...filterParams,
                    pagination: {
                        limit: paginationLimit,
                        page: currentPage
                    }
                }
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    };

    const recipientName = (moviment: IMovimentLog) => {
        return moviment?.truck?.name || moviment?.professional?.name 
    }

    return (
        <>
            <CreateMovimentModal
                isOpen={createMovimentModalControl.isOpen}
                onClose={createMovimentModalControl.onClose}
            />
            {/* {receiptSelected && (
                <UpdateReceiptsModal
                    isOpen={updateReceiptsModalControl.isOpen}
                    onClose={updateReceiptsModalControl.onClose}
                    receipt={receiptSelected}
                />
            )} */}
            {/* {campaingSelected && (
                <ReportCampaignModal
                    isOpen={reportCampaignModalControl.isOpen}
                    onClose={reportCampaignModalControl.onClose}
                    campaign={campaingSelected}
                />
            )} */}
            <FiltersMovimentsModal
                isOpen={filterMovimentModalControl.isOpen}
                onClose={filterMovimentModalControl.onClose}
                handleAddFilter={handleAddFilter}
                onFilterSubmit={setFilters}
            />
            {/* <ModalConfirmation
                isOpen={toggleCampaignModalControl.isOpen}
                onClose={toggleCampaignModalControl.onClose}
                nameAction="ativar/desativar essa campanha"
                handleConfirm={handleCampaignToggle}
                loading={toggleCampaingLoad}
            /> */}
            <Styles.BackgroundPanel>
                <PanelHeader
                    title="Movimentações"
                    isFilterModalVisible
                    isNew
                    onClick={filterMovimentModalControl.onOpen}
                    onClickNew={createMovimentModalControl.onOpen}
                />
                {/* <Search
                    wordSearch={searchInput}
                    setSearch={handleWordSearch}
                    isFilterForRegister
                    limit={paginationLimit}
                    onSetPageSize={(limit) => {
                        setCurrentPage(1)
                        setPaginationLimit(limit)
                    }}
                    placeholder="Pesquise numero da NF"
                    type="number"
                /> */}
                {
                    filters.length
                        ? (
                            <FilterList filters={filters} removeFilter={handleRemoveFilter} />
                        )
                        : ''
                }
                {
                    data &&  (
                        <Table
                            headers={columnMovimentssHeaders}
                            data={listMoviments.map((moviment: IMovimentLog) => ({
                                id: moviment.id,
                                createdAt:  format(parseISO(moviment.created_at), "dd/MM/yyyy"),
                                product:  moviment.product?.name,
                                recipient: recipientName(moviment),
                                action_type: normalizeWord(moviment.action_type || ""),
                                quantity: moviment.quantity,
                                stock_unit: normalizeWord(moviment.product?.stock_unit || ""),
                                moved_by: moviment.moved_by,
                                // actions: [
                                //     // {
                                //     //     name: "Relatoirio",
                                //     //     icon: <ReportButton />,
                                //     //     action: () => handleCampaignReport(campaign),
                                //     // },
                                //     // {
                                //     //     name: "Editar",
                                //     //     icon: <EditButton />,
                                //     //     action: () => handleReceiptUpdate(receipt),
                                //     // },
                                //     // {
                                //     //     name: "Desativar",
                                //     //     icon: <CancelButton/>,
                                //     //     action: handleToggleConfirmation,
                                //     // },
                                // ],
                            }))}
                        />
                    )
                }
                {
                    data && (
                        <Pagination
                                totalPage={data.stockActionLogs.totalPages}
                                pageIndex={currentPage}
                                setPage={setCurrentPage}
                        />
                    )
                }
            </Styles.BackgroundPanel>
        </>
    )
}

export default Moviment