import React, { useCallback, useEffect, useRef, useState } from "react";

import Text from "../../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../../components/InputRef";
import Button from "../../../components/Button";
import colors from "../../../shared/utils/constants/colors";
import * as Yup from "yup";

import * as Styles from "./styles";
import { FormHandles } from "@unform/core";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import getValidationErrors from "../../../shared/utils/getValidationErrors";
import Tab from "../../../components/Tab/index";
import { Tabs, Content } from "../../../components/Tab/styles";
import masks, { IMaskOptions } from "../../../shared/utils/masks";
import Switch from "../../../components/Switch";
import { Label } from "../../../components/Label/styles";
import {
  getCategories,
  getServicesByCategory,
} from "../../../services/api-graphql-calls";
import { launchToast } from "../../../shared/utils/launchToast";
import {
  addCoupomToCustomer,
  CoupomSegmentation,
  getCustomers,
  insertCoupon,
  removeCustomer,
} from "../../../services/coupon/couponService";
import removeSymbolsOfString from "../../../shared/utils/removeSymbolsOfString";
import CustomerList from "./CustomerList";
import TextInput from "components/TextInput";

interface FormData {
  name: string;
}

const serviceRestrictions = [
  { value: "none", label: "Nenhum" },
  { value: "category", label: "Categoria" },
  { value: "service", label: "Serviço" },
];

const defaultSelectedCategory = {
  value: 'none',
  label: 'Nenhuma'
};

const defaultSelectedService = {
  value: 'none',
  label: 'Nenhum'
};

const SchedulingModal = ({ visible, onCloseModal, reciviedCoupon }: any) => {
  const formRef = useRef<FormHandles>(null);

  const [activedTab, setActivedTab] = useState(0);
  const [selectedServiceRestriction, setSelectedServiceRestriction] = useState(
    serviceRestrictions[0] as any
  );
  const [categories, setCategories] = useState([] as any);
  const [services, setServices] = useState([] as any);
  const [selectedCategory, setSelectedCategory] = useState(defaultSelectedCategory);
  const [selectedService, setSelectedService] = useState([] as any);
  const [description, setDescription] = useState("");
  const [update, setUpdate] = useState(false);
  const [coupon, setCoupon] = useState({} as any);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [editing, setEditing] = useState(false);
  const [notUsed, setNotUsed] = useState([] as any);
  const [id, setId] = useState("");
  const [inactive, setInactive] = useState(0);
  const [cpf, setCpf] = useState("" as any);
  const [customerLIst, setCustomerLIst] = useState([] as any);

  useEffect(() => {
    if (!!reciviedCoupon.id) {
      setUpdate(true);
      setCoupon(reciviedCoupon);
      loadFields(reciviedCoupon);
      loadCustomers(reciviedCoupon.id);
    } else {
      setCoupon({});
      setUpdate(false);
      formRef.current?.setFieldValue("only_new_buyer", "Não");
      formRef.current?.setFieldValue("can_accumulate", "Não");
      formRef.current?.setFieldValue("campaign", "Sim");
      formRef.current?.setFieldValue("dateRestrict", "Não");
      setSelectedServiceRestriction({ value: "none", label: "Nenhum" });
    }
  }, [reciviedCoupon]);

  useEffect(() => {
    if (!!coupon.service_id && loadingUpdate) {
      const selectedService = services.find(
        (c: any) => c.value === coupon.service_id
      );
      setSelectedService(selectedService);
    }
  }, [services, coupon.service_id, loadingUpdate]);

  const loadCustomers = async (coupon_id: string) => {
    const result = await getCustomers(coupon_id);
    let list = [] as any;
    for (let r of result) {
      const { user } = r;
      const { cpf, name } = user[0];
      list.push({
        cpf,
        name,
      });
    }

    setCustomerLIst(list);
  };

  const loadFields = async (coupon: any) => {
    await setLoadingUpdate(true);

    const {
      id,
      only_new_buyer,
      can_accumulate,
      campaign,
      description,
      category_id,
      service_id,
      date_in,
      date_out,
      visible_to_all,
    } = coupon;

    setId(id);

    formRef.current?.setData(coupon);

    formRef.current?.setFieldValue(
      "visible_to_all",
      visible_to_all ? "Sim" : "Não"
    );
    formRef.current?.setFieldValue(
      "only_new_buyer",
      only_new_buyer ? "Sim" : "Não"
    );
    formRef.current?.setFieldValue(
      "can_accumulate",
      can_accumulate ? "Sim" : "Não"
    );
    formRef.current?.setFieldValue("campaign", campaign ? "Sim" : "Não");
    formRef.current?.setFieldValue(
      "dateRestrict",
      date_in || date_out ? "Sim" : "Não"
    );

    if (service_id) {
      setSelectedServiceRestriction(serviceRestrictions[2]);
    } else if (category_id) {
      setSelectedServiceRestriction(serviceRestrictions[1]);
    } else {
      setSelectedServiceRestriction(serviceRestrictions[0]);
    }

    setDescription(description);
  };

  const loadServices = useCallback(async (category) => {
    if (category.value !== 'none') {
      let where = {} as any;
      where.category_id = category.value;
      const result = await getServicesByCategory({ where });
      let services = result.map((r: any) => {
        const { id: value, name: label, groups } = r;
        return {
          value,
          label,
          groups,
        };
      });
      setServices([defaultSelectedService, ...services]);
    }
  }, []);

  useEffect(() => {
    if (!!selectedCategory.value) {
      loadServices(selectedCategory);
    }
    if (!loadingUpdate) {
      setSelectedService([]);
    }
  }, [selectedCategory, loadServices, loadingUpdate]);

  useEffect(() => {
    if (!!coupon.category_id && loadingUpdate) {
      const selectedCategory = categories.find(
        (c: any) => c.value === coupon.category_id
      );
      setSelectedCategory(selectedCategory);
    } else {
      setSelectedCategory(defaultSelectedCategory);
    }
  }, [
    selectedServiceRestriction,
    categories,
    coupon.category_id,
    loadingUpdate,
  ]);

  const loadCategories = useCallback(async () => {
    const result = (await getCategories()) as any;
    const reciviedCategories = result.categories as any;
    let categories = [defaultSelectedCategory];
    for (let k in reciviedCategories) {
      if (reciviedCategories[k].name !== "Vacinas") {
        categories.push({
          value: reciviedCategories[k].id,
          label: reciviedCategories[k].name,
        });
      }
    }

    setCategories(categories);
  }, []);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const applyMask = useCallback((value: string, mask?: IMaskOptions) => {
    return mask ? masks[mask](value) : value;
  }, []);

  const handleActive = (tab: number) => {
    if (tab === 2 && id === "") return;
    setActivedTab(tab);
  };

  const handleSubmit = async (data: FormData) => {
    formRef.current?.setErrors({});
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("Nome é obrigatório"),
        code: Yup.string().required("Código é obrigatório"),
        amount: Yup.string().required("Valor é obrigatório"),
        starts_in: Yup.string().required("Data Início é obrigatória"),
        ends_in: Yup.string().required("Data Fim é obrigatória"),
      });

      await schema.validate(data, { abortEarly: false });

      let service_id = selectedService.value !== 'none' ? selectedService.value : null;
      let category_id = selectedCategory.value !== 'none' ? selectedCategory.value : null;

      try {
        const response = (await insertCoupon({
          ...data,
          description,
          category_id,
          service_id,
        })) as any;

        launchToast("Cupom salvo com sucesso", "success");
        setUpdate(true);
        setEditing(false);
        setId(response.id);
        onCloseModal();
      } catch (error: any) {
        launchToast(error.message, "error");
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);
      launchToast("Verifique o preenchimento dos dados", "error");
    }
    // setLoading(false);
    return false;
  };

  const handleDiscard = (e: any) => {
    formRef.current?.reset();
    formRef.current?.setErrors({});
    setSelectedServiceRestriction([]);
    setSelectedCategory(defaultSelectedCategory);
    setSelectedService([]);
    setNotUsed([]);
    setDescription("");
    setActivedTab(0);
    setId("");
    onCloseModal();
  };

  const hadleSegmetation = async () => {
    if (id === "") {
      launchToast("Efetuar inclusão do cupom primeiro", "warning");
    } else {
      let where = {
        inativeUserDays: parseFloat(inactive.toString()),
        notUseCategoryId: notUsed.value,
      };
      await CoupomSegmentation(id, where);
    }
  };

  const handleAddByCPF = async () => {
    if (id === "") {
      launchToast("Efetuar inclusão do cupom primeiro", "warning");
    } else {
      await addCoupomToCustomer(id, removeSymbolsOfString(cpf));
    }
  };

  const hadleDeleteCustomer = async (cpf: string) => {
    await removeCustomer(id, cpf);
    loadCustomers(id);
  };

  const handleClose = () => {
    handleDiscard("onclose");
    onCloseModal();
  };

  return (
    <>
      <Modal visible={visible} closeModal={() => handleClose()}>
        <Styles.header>
          <Text
            text="Novo Cupom"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={handleClose}
          />
        </Styles.header>

        <div style={{ width: "100%" }}>
          <Tabs>
            <Tab
              activeTab={handleActive}
              active={activedTab === 0}
              id={0}
              title="informações gerais"
            />
            <Tab
              activeTab={handleActive}
              active={activedTab === 1}
              id={1}
              title="regras"
            />
            <Tab
              activeTab={handleActive}
              active={activedTab === 2}
              id={2}
              title="segmentação"
            />
          </Tabs>
          <Styles.line></Styles.line>

          <Styles.FormDiv>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Content active={activedTab === 0}>
                <Styles.row>
                  <TextInput
                      label="Nome"
                      placeholder="Digite a nome"
                      name="name"
                      type="text"
                  />
                  <Styles.field>
                    <Label>data início</Label>
                    <InputRef name="starts_in" type="date" />
                  </Styles.field>

                  <Styles.field>
                    <Label>Data fim</Label>
                    <InputRef name="ends_in" type="date" />
                  </Styles.field>
                </Styles.row>

                <Styles.row>
                  <Styles.field>
                    <Label>Código</Label>
                    <InputRef
                      placeholder="Digite o código"
                      name="code"
                      type="text"
                      disabled={update}
                    />
                  </Styles.field>
                  <Styles.field>
                    <Label>Tipo de desconto</Label>
                    <Switch
                      name="type"
                      options={[
                        { value: "$", label: "Valor" },
                        { value: "%", label: "Porcentagem" },
                      ]}
                      disabled={update}
                    />
                  </Styles.field>
                  <Styles.field>
                    <Label>valor</Label>
                    <InputRef
                      placeholder="Digite o valor"
                      name="amount"
                      type="text"
                      disabled={update}
                      onChange={(e) =>
                        formRef.current?.setFieldValue("amount", e.target.value)
                      }
                    />
                  </Styles.field>
                </Styles.row>
                <Styles.row>
                  <Styles.field>
                    <Label>campanha</Label>
                    <Switch
                      name="campaign"
                      options={[
                        { value: "Sim", label: "yes" },
                        { value: "Não", label: "no" },
                      ]}
                    />
                  </Styles.field>
                  <Styles.field>
                    <Label>new buyer</Label>
                    <Switch
                      name="only_new_buyer"
                      options={[
                        { value: "Sim", label: "Valor" },
                        { value: "Não", label: "Porcentagem" },
                      ]}
                    />
                  </Styles.field>
                </Styles.row>

                <Styles.row>
                  <Styles.field>
                    <Label>restrição de data</Label>
                    <Switch
                      name="dateRestrict"
                      options={[
                        { value: "Sim", label: "yes" },
                        { value: "Não", label: "no" },
                      ]}
                    />
                  </Styles.field>
                  <Styles.field>
                    <Label>somente no dia</Label>
                    <InputRef name="date_in" type="date" />
                  </Styles.field>
                  <Styles.field>
                    <Text
                      text="EXCETO NO DIA"
                      color={colors.argon.textColorDark}
                      fontFamily="Open Sans"
                      size={14}
                      weight="600"
                      marginBottom={5}
                    />
                    <InputRef name="date_out" type="date" />
                  </Styles.field>
                </Styles.row>
                <Styles.row>
                  <Styles.field>
                    <Label>Categoria</Label>
                    <Select
                      name="category"
                      containerStyle={{ width: "33rem" }}
                      placeholder="Nenhuma"
                      options={categories}
                      value={selectedCategory}
                      onChange={(e: any) => setSelectedCategory(e)}
                    />
                  </Styles.field>
                  <Styles.field>
                    <Label>Serviço</Label>
                    <Select
                      name="service"
                      containerStyle={{ width: "33rem" }}
                      placeholder="Serviço"
                      options={services}
                      value={selectedService}
                      isDisabled={selectedCategory.value === 'none'}
                      onChange={(e: any) => setSelectedService(e)}
                    />
                  </Styles.field>
                </Styles.row>
                <Styles.row>
                  <Styles.field>
                    <Label>Exibir para todos os cliente</Label>
                    <Switch
                      name="visible_to_all"
                      options={[
                        { value: "Sim", label: "yes" },
                        { value: "Não", label: "no" },
                      ]}
                    />
                  </Styles.field>
                </Styles.row>

                {!update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton
                      name="discard"
                      onClick={(e) => handleDiscard(e)}
                      type="button"
                    >
                      Descartar
                    </Styles.WhiteButton>
                    <Button
                      name="salve"
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}

                {!editing && update && (
                  <Styles.rowButton margin={"89"}>
                    <Styles.EditButton type="button" disabled>
                      Editar
                    </Styles.EditButton>
                  </Styles.rowButton>
                )}

                {editing && update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton
                      onClick={(e: any) => handleDiscard(e)}
                      type="button"
                    >
                      Descartar alterações
                    </Styles.WhiteButton>
                    <Button
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}
              </Content>

              <Content active={activedTab === 1}>
                <Styles.row>
                  <Styles.field>
                    <Label>Regras</Label>
                    <Styles.TextArea
                      name="rulesDescription"
                      value={description}
                      onChange={(e: any) => setDescription(e.target.value)}
                      placeholder="Ao escrever as regras e utilize “/“ para pular linhas"
                    />
                  </Styles.field>
                </Styles.row>

                {!update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton
                      name="discard"
                      onClick={(e) => handleDiscard(e)}
                      type="button"
                    >
                      Descartar
                    </Styles.WhiteButton>
                    <Button
                      name="salve"
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}

                {!editing && update && (
                  <Styles.rowButton margin={"89"}>
                    <Styles.EditButton type="button">Editar</Styles.EditButton>
                  </Styles.rowButton>
                )}

                {editing && update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton
                      onClick={(e: any) => handleDiscard(e)}
                      type="button"
                    >
                      Descartar alterações
                    </Styles.WhiteButton>
                    <Button
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}
              </Content>

              <Content active={activedTab === 2}>
                <Styles.row>
                  <Styles.field>
                    <Label>usuários inativos a x dias</Label>
                    <InputRef
                      placeholder="Digite a quantidade de dias"
                      name="inactive"
                      type="number"
                      value={inactive}
                      onChange={(e: any) => setInactive(e.target.value)}
                    />
                  </Styles.field>

                  <Styles.field>
                    <Label>usuário não utilizou o serviço</Label>
                    <Select
                      name="service"
                      placeholder="Serviço não utilizado"
                      options={categories}
                      style={{ width: "33rem" }}
                      value={notUsed}
                      onChange={(e: any) => setNotUsed(e)}
                    />
                  </Styles.field>
                  <Styles.field>
                    <Label>Estabeliciementos parceiros</Label>
                    <Select
                      name="estabelicimentos"
                      placeholder="Estabelicimentos"
                      style={{ width: "33rem" }}
                      isDisabled={true}
                    />
                  </Styles.field>
                </Styles.row>

                <Styles.row>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Styles.field>
                      <Label>Região</Label>
                      <Select
                        name="region"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Região"
                        isDisabled={true}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>Bairro</Label>
                      <Select
                        name="neighborhood"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Bairros"
                        isDisabled={true}
                      />
                    </Styles.field>

                    <Styles.SegmentationButton
                      type="button"
                      onClick={hadleSegmetation}
                    >
                      Aplicar Segmentação
                    </Styles.SegmentationButton>
                  </div>
                </Styles.row>

                <Styles.line></Styles.line>

                <div style={{ display: "flex" }}>
                  <Text
                    text="USUÁRIOS ESPECÍFICOS"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={14}
                    weight="600"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <div>
                    <Label>CPF</Label>
                    <InputRef
                      placeholder="Digite o cpf"
                      name="cpf"
                      type="text"
                      value={cpf}
                      containerStyle={{ width: "" }}
                      onChange={(e: any) =>
                        setCpf(applyMask(e.target.value, "cpf"))
                      }
                    />
                  </div>
                  <Styles.addCustomerButton
                    type="button"
                    onClick={handleAddByCPF}
                  >
                    Adicionar cupom
                  </Styles.addCustomerButton>
                </div>
                {customerLIst.length > 0 && (
                  <CustomerList
                    customerList={customerLIst}
                    hadleDeleteCustomer={hadleDeleteCustomer}
                  />
                )}

                {!update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton
                      name="discard"
                      onClick={(e) => handleDiscard(e)}
                      type="button"
                    >
                      Descartar
                    </Styles.WhiteButton>
                    <Button
                      name="salve"
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}

                {!editing && update && (
                  <Styles.rowButton margin={"89"}>
                    <Styles.EditButton type="button">Editar</Styles.EditButton>
                  </Styles.rowButton>
                )}

                {editing && update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton
                      onClick={(e: any) => handleDiscard(e)}
                      type="button"
                    >
                      Descartar alterações
                    </Styles.WhiteButton>
                    <Button
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}
              </Content>
            </Form>
          </Styles.FormDiv>
        </div>
      </Modal>
    </>
  );
};
export default SchedulingModal;
