import { FetchResult } from "@apollo/client";

export type ICreateOutputReasonFormProps = {
  onSubmit: (payload: ICreateOutputReasonFormData) => Promise<FetchResult<any>>;
}

export type IOutputReasonBase = {
  name: string
  type: 'NonIdentifyLoss' | 'IdentifyLoss' | 'Promotional' | 'FixedAssets'
}

export type ICreateOutputReasonFormData = IOutputReasonBase;

export enum OutputReasonsTypeTranslation {
  "NonIdentifyLoss" = "Perda não identificada",
  "IdentifyLoss" = "Perda identificada",
  "Promotional" = "Promocional",
  "FixedAssets" = "Ativo fixo imobilizado",
}

