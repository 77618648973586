import { gql } from "@apollo/client";
import client from "./api-graphql";
import ICategory from "types/ICategory";

interface IFiltersAppointments {
  pagination?: {
    limit: number;
    page: number;
  };
  order?: {
    by: string;
    direction: "ASC" | "DESC";
  }[];
  where: {
    from?: string;
    to?: string;
  };
}

const getCategories = async (): Promise<{ categories: ICategory[] }> => {
  const response = await client.query({
    query: gql`
      query {
        categories {
          id
          name
        }
      }
    `,
  });

  return response.data;
};

const getServicesByCategory = async (
  filters?: IFiltersAppointments
): Promise<any[]> => {
  const response = await client.query({
    query: gql`
       {
        services${
          filters
            ? `(filters: ${JSON.stringify(filters).replace(
                /"([^"]+)":/g,
                "$1:"
              )})`
            : ""
        } {
          id
          name
          groups {
            id
            name
            items {
              id
              name
            }
          }      
       }
     }
     `,
  });
  return response.data.services;
};

const getRoles = async (): Promise<any[]> => {
  const response = await client.query({
    query: gql`
      query Roles {
        roles {
          name
          id
        }
      }
    `,
  });

  return response.data;
};

const getTrucks = async (): Promise<any[]> => {
  const response = await client.query({
    query: gql`
      query Trucks {
        trucks {
          id
          name
        }
      }
    `,
  });

  return response.data;
};

const getServices = async (cep: string): Promise<any[]> => {
  const response = await client.query({
    query: gql`
      query Services {
        services {
          id
          name
          category {
            id
          }
          groups {
            name
            items {
              id
              name
              prices(cep: ${JSON.stringify(cep)}) {
                specie_id
                size
                price
              }
            }
          }
          prices(cep: ${JSON.stringify(cep)}) {
            price
            size
              specie_id
            
          }
        }
      }
    `,
  });

  return response.data;
};

const getCeps = async (): Promise<any[]> => {
  const response = await client.query({
    query: gql`
      query GetAllCep {
        getAllCep {
          cep
          status
        }
      }
    `,
  });

  return response.data.getAllCep;
};

const verifyCep = async (cep: string): Promise<any[]> => {
  const response = await client.query({
    query: gql`
    {
      verifyCepZone${cep ? `(cep: ${JSON.stringify(cep)})` : ""} }
  `,
  });
  return response.data.verifyCepZone;
};

const verifyCepCategory = async (
  cep: string,
  shouldOverrideAdmin: boolean = false
): Promise<any> => {
  const response = await client.query({
    query: gql`
      query (
        $filters: verifyCepsCategoriesInputType!
        $shouldOverrideAdmin: Boolean
      ) {
        verifyCepsCategories(
          filters: $filters
          shouldOverrideAdmin: $shouldOverrideAdmin
        ) {
          bathAndGrooming
        }
      }
    `,
    variables: {
      filters: {
        where: { cep },
      },
      shouldOverrideAdmin,
    },
  });

  return response.data.verifyCepsCategories.bathAndGrooming;
};

const getUserAdress = async (user_id: string): Promise<any[]> => {
  const response = await client.query({
    query: gql`
    {
      AddressesCustomerByAdmin(user_id: ${JSON.stringify(user_id)}){
        id
        cep
        street
        number
        neighborhood
        city
        state
        complement
      } }
  `,
  });
  return response.data.AddressesCustomerByAdmin;
};

export {
  getCategories,
  getRoles,
  getTrucks,
  getServices,
  getCeps,
  verifyCep,
  getUserAdress,
  getServicesByCategory,
  verifyCepCategory,
};
