import { gql } from "@apollo/client";

export const CREATE_PRODUCT = gql`
  mutation ($input: CreateProductInputType!) {
    createProduct(input: $input) {
      id
    }
  }
`;

export const GET_PRODUCTS = gql`
  query {
    products {
      products {
        id
        name
        description
        stockUnit
        manufacturerSkuCode
        multiplier
        packaging {
          name
        }
        category {
          name
        }
        accountAssignment {
          name
        }
        item {
          name
        }
      }
      totalPages
    }
  }
`;

export const GET_PACKAGINGS = gql`
  query {
    packagings {
      id
      name
    }
  }
`;

export const GET_ITEMS = gql`
  query ProductItems {
    productItems {
      id
      name
    }
  }
`;
