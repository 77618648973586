import colors from "shared/utils/constants/colors";
import styled from "styled-components";

export const PanelContainer = styled.main`
  width: 100%;
  height: 100%;
  gap: 32px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
`;

export const PainelItem = styled.div`
  background: ${colors.secondary.default};
  padding: 48px 0;
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 0px 8px ${colors.secondary.dark};
  cursor: pointer;
`;
