import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { launchToast } from "../../../shared/utils/launchToast";
import processError from "../../../shared/utils/processError";
import updateHourAppointmentsSequence from "../../../shared/utils/updateHourAppointmentsSequence";
import IAppointment from "../../../types/IAppointment";
import ITruck from "../../../types/ITruck";
import { updateAppointmentInRoute } from "../graphQL";
import ItemList from "./ItemList";
import { Alert, Container, GroupContainer, TitleContainer } from "./styles";

interface IAppointmentByProfessionals {
  professional: {
    id?: string;
    name: string;
    changes_pending: boolean;
    truck?: ITruck;
  };
  appointments: IAppointment[];
}

type IProps = {
  data: IAppointmentByProfessionals[];
};

type IPropsEditExecutionTime = {
  group_index: number;
  appointment_updated: IAppointment;
};

const ScheduleBoard = ({ data }: IProps) => {
  const [groups, setGroups] = useState<IAppointmentByProfessionals[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setGroups(data);
  }, [data]);

  const handleSaveChanges = async (group_index: number) => {
    setLoading(true);
    try {
      const appointments = await updateAppointmentInRoute(
        groups[group_index].appointments.map((ap) => ({
          id: ap.id,
          professional_id: groups[group_index].professional.id,
          hour: ap.hour,
        }))
      );

      setGroups((oldState) => {
        oldState[group_index].appointments = appointments;
        oldState[group_index].professional.changes_pending = false;
        return oldState;
      });
    } catch (error) {
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const reorder = (
    list: IAppointment[],
    sourceIndex: number,
    destinationIndex: number,
    reset_hour: boolean,
    truck?: ITruck
  ): IAppointment[] => {
    const result = Array.from(list);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    if (destinationIndex > 0) {
      let hour_base = list[0].hour;
      const aps = result.filter((a) => !!a);
      return updateHourAppointmentsSequence({
        appointments: aps,
        start_hour: hour_base,
        truck: truck,
        reset_hour: reset_hour,
      });
    } else {
      let hour_base = list[0].hour;
      return updateHourAppointmentsSequence({
        appointments: result,
        start_hour: hour_base,
        truck: truck,
        reset_hour: reset_hour,
      });
    }
  };

  const move = (
    source: IAppointment[],
    destination: IAppointment[],
    droppableSource: any,
    droppableDestination: any,
    truckSource?: ITruck,
    truckDestination?: ITruck
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    return {
      sourceClone:
        droppableSource.droppableId === "0"
          ? sourceClone
          : reorder(
              sourceClone,
              droppableSource.index,
              droppableSource.index,
              false,
              truckSource
            ),
      destClone: reorder(
        destClone,
        droppableDestination.index,
        droppableDestination.index,
        droppableDestination.droppableId === "0",
        truckDestination
      ),
    };
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    // dropped outside the list
    if (
      !destination ||
      (source.index === destination.index &&
        source.droppableId === destination.droppableId)
    ) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(
        groups[sInd].appointments,
        source.index,
        destination.index,
        destination.droppableId === "0",
        groups[sInd].professional.truck
      );
      const newState = groups;
      newState[sInd].appointments = items;
      newState[sInd].professional.changes_pending = true;
      setGroups(newState);
    } else {
      const result = move(
        groups[sInd].appointments,
        groups[dInd].appointments,
        source,
        destination,
        groups[sInd].professional.truck,
        groups[dInd].professional.truck
      );
      const newState = [...groups];
      newState[sInd].appointments = result.sourceClone as any;
      newState[dInd].appointments = result.destClone as any;
      newState[dInd].professional.changes_pending = true;
      setGroups(newState);
    }
  };

  const handleEditAppointment = ({
    group_index,
    appointment_updated,
  }: IPropsEditExecutionTime): IAppointment => {
    try {
      const groups_tmp = groups.map((group) => {
        return {
          professional: group.professional,
          appointments: group.appointments.map((appointment, index) => {
            if (appointment.id === appointment_updated.id) {
              return appointment_updated;
            }
            return appointment;
          }),
        };
      });

      const appointmentsRescheduled = groups_tmp.map((group, index) => {
        if (index === group_index) {
          group.appointments = updateHourAppointmentsSequence({
            appointments: group.appointments,
            start_hour: group.appointments[0].hour || appointment_updated.hour,
            truck: group.professional.truck,
            reset_hour: group_index === 0, //group to be scheduled
          });
          group.professional.changes_pending = true;
        }
        return group;
      });
      setGroups(appointmentsRescheduled);
      return appointment_updated;
    } catch (error) {
      throw error;
    }
  };

  return (
    <Container>
      <div style={{ display: "flex" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {groups.map((group, group_index) => (
            <Droppable droppableId={group_index.toString()} key={group_index}>
              {(provided) => (
                <GroupContainer
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <TitleContainer>
                    <b>
                      {group.professional.name} -{" "}
                      {group.professional.truck?.licensePlate || "sem truck"}
                    </b>
                    <Alert
                      pending={group.professional.changes_pending}
                      disabled={!group.professional.changes_pending}
                      loading={loading}
                      onClick={() => handleSaveChanges(group_index)}
                    />
                  </TitleContainer>
                  {group.appointments.map((appointment, appointment_index) => (
                    <ItemList
                      key={appointment.id}
                      appointment={appointment}
                      index={appointment_index}
                      handleEditAppointment={(appointment: IAppointment) =>
                        handleEditAppointment({
                          group_index: group_index,
                          appointment_updated: appointment,
                        })
                      }
                    />
                  ))}
                </GroupContainer>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
    </Container>
  );
};

export default ScheduleBoard;
