import { useQuery } from '@apollo/client';
import Button from 'components/Button';
import Header from 'components/Header';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Table from 'components/Table';
import { GET_WAREHOUSES } from 'pages/StockWarehouses/constants/graphQL';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { warehousesTableHeaders } from './constants';
import { IWarehousesResponse } from './constants/types';

function StockWarehouses() {
  const { data, loading: queryLoading } = useQuery<{ warehouses: IWarehousesResponse[] }>(GET_WAREHOUSES);

  const history = useHistory();

  return (
    <MainContainer>
      <Header title="ARMAZENS" />
      <Button
        text="novo"
        styleContainer={{
          justifyContent: 'flex-end',
          margin: '0px 12px 8px 0px',
        }}
        type="button"
        onClick={() => history.push('/stock-warehouses/create')}
      />
      {queryLoading && <Loading />}
      {data && (
        <Table
          data={data.warehouses}
          headers={warehousesTableHeaders}
        />
      )}
    </MainContainer>
  );
}

export default StockWarehouses;
