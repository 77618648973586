import { gql } from "@apollo/client";

export const GET_SUPPLIERS = gql`
  query {
    suppliers {
      id
      name
      cnpj
      email
      phoneNumber
    }
  }
`

export const REGISTER_SUPPLIERS = gql`
  mutation CreateSupplier($input: CreateSupplierInputType!) {
    createSupplier(input: $input) {
      id
    }
  }
`;
