import * as Yup from "yup";

export const CreateAddressFormValidationSchema = Yup.object().shape({
  cep: Yup.string().required('CEP é obrigatório'),
  city: Yup.string().required('Cidade é obrigatória'),
  state: Yup.string()
    .required('Estado é obrigatório')
    .max(2, 'Coloque a sigla do estado em 2 caracteres'),
  street: Yup.string().required('Endereço é obrigatório'),
  number: Yup.string().required('Número é obrigatório'),
  neighborhood: Yup.string().required('Bairro é obrigatório'),
  complement: Yup.string(),
  surname: Yup.string(),
});
