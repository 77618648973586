import Text from 'components/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PainelItem } from '../styles';

export type IPanelCardProps = {
  name: string
  path: string
}

function PanelCard({
  name,
  path
}: IPanelCardProps) {
  const history = useHistory();

  return (
    <PainelItem onClick={() => history.push(path)}>
      <Text
        text={name}
        size={24}
        clickable
      />
    </PainelItem>
  );
}

export default PanelCard;
