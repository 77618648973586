import { FetchResult } from "@apollo/client"

export type IProductBase = {
  name: string
  description: string
  stockUnit: keyof typeof IProductStockUnitTranslation
  manufacturerSkuCode: string
  multiplier: number | undefined
}

export type ICreateProductFormProps = {
  onSubmit: (payload: ICreateProductFormData) => Promise<FetchResult<any>>;
}

export type ICreateProductFormData = IProductBase & {
  packagingId: string
  productCategoryId: string
  accountAssignmentId: number | undefined
  itemId: string
}

export type IExtendedProduct = IProductBase & {
  id: string
  category: {
    name: string
  }
  accountAssignment: {
    name: string
  }
  item: {
    name: string
  }
}

export type IProductsResponse = {
  products: IExtendedProduct[]
  totalPages: number
}

export enum IProductStockUnitTranslation {
  LITER = "litro",
  MILLILITRE = "mililitro",
  GRAM = "grama",
  KILOGRAM = "quilo",
  MILIGRAM = "miligrama",
  UNITY = "unidade"
}

export type IPackagingsResponse = {
  id: string
  name: string
}

export type IItemsResponse = {
  id: string
  name: string
}
